<template>
  <div class="sub_content_arr">
      <div>
          <div class="subTitle_arr">
              <h2>환전하기 <small>exchange </small></h2>
          </div>

          <div class="cont_back_arr01">
              <div class="cash_notice_zone">

                  <div class="title">환전방법</div>
                  <ul>
                      <li>※ 출금규정<br>
                          <br>
                          ※ 1회 최소 환전금액: <span style="color:#61FFFF;">10,000원 </span> / 1회 최대 환전금액: <span
                                  style="color:#61FFFF;">무제한</span><br>
                          <br>
                          ※ 재환전 대기시간은 없습니다. <span style="color:#61FFFF;"> 다만 짧은 시간에 환전을 계속 누르는 행위는 피해주시길 바랍니다.</span> <br>
                          <br>
                          ※ 모든 환전은 순차적으로 진행되며 환전 대기 인원이 많을 경우 <span style="color:#61FFFF;">최대 30분 </span>까지 소요될 수도
                          있습니다.<br>
                          <br>
                          ※ 회원가입 시 기재된<span style="color:#61FFFF;"> 본인 명의 계좌만 출금이 가능 </span>합니다.<br>
                          <br>
                          ※ 출금 신청은 <span style="color:#61FFFF;">전액출금</span> 을 해주셔야 <span style="color:#61FFFF;">출금</span>이
                          가능합니다.<br>
                          <br>
                          ※ 마일리지 단독 이용 시 <span style="color:#61FFFF;">최대 5배</span> 출금이 가능 하며 , 초과되는 금액은 <span
                                  style="color:#61FFFF;">회수</span>처리 되십니다.
                      </li>
                  </ul>
              </div>
              <div class="cashzone_talbe_arr">
                  <table border="0" cellspacing="0" cellpadding="0" width="100%" class="cash_zone_table bbs-list">
                      <tbody>
                      <tr>
                          <th>보유금액</th>
                          <td align="left" style="padding:10px; ">&nbsp;&nbsp;<b><font color="#f22200" size="3">{{ user.members_cash | makeComma }} 원</font></b>
                          </td>
                      </tr>
                      <tr>
                          <th>환전금액</th>
                          <td style="padding:10px; ">
                              <input name="money" type="text" maxlength="12" class="exchangeInput money bg_input"
                                    id="money"
                                    v-model="amount" @focusout="amountRound">
                              &nbsp;&nbsp; 
                              <a @click="changeAmount(10000)" class="ui_btn_gray" style="cursor:pointer;"> 1만원</a> 
                              <a @click="changeAmount(30000)" class="ui_btn_gray" style="cursor:pointer;"> 3만원</a> 
                              <a @click="changeAmount(50000)" class="ui_btn_gray" style="cursor:pointer;">5만원</a>
                              <a @click="changeAmount(100000)" class="ui_btn_gray" style="cursor:pointer;">10만원</a>
                              <a @click="changeAmount(200000)" class="ui_btn_gray" style="cursor:pointer;">20만원</a> 
                              <a @click="changeAmount(300000)" class="ui_btn_gray" style="cursor:pointer;">30만원</a>
                              <a @click="changeAmount(500000)" class="ui_btn_gray" style="cursor:pointer;">50만원</a>
                              <a @click="changeAmount(1000000)" class="ui_btn_gray">100만원</a>
                              <a @click="changeAmount(5000000)" class="ui_btn_gray">500만원</a>
                              <a @click="changeAmount(10000000)" class="ui_btn_gray">1000만원</a>

                              <a @click="resetAmount" class="ui_btn_blue" style="cursor:pointer;">정정</a></td>
                            </tr>
                      <tr>
                          <td align="left" colspan="2" style="padding:10px; ">
                            <span style="color: #ff3434;font-size: 12px;font-weight: normal;">최소 
                              <span class="money" style="text-align: right; padding-right: 5px; font-weight: bold;">10,000원</span>이상, 
                              <span class="money" style="text-align: right; padding-right: 5px; font-weight: bold;">10,000원</span> 단위로 환전 가능합니다.</span>
                          </td>
                      </tr>
                      <tr>
                          <td align="left" colspan="2" style="padding:10px; ">
                              <ul>
                                  <li style="margin: 4px 0;"><strong>거래은행</strong> : {{ user.members_cashout_bank }}</li>
                                  <li style="margin: 4px 0;"><strong>계좌번호</strong> : {{ user.members_cashout_account }}</li>
                                  <li style="margin: 4px 0;"><strong>예금주</strong> : {{ user.members_cashout_owner }}</li>
                              </ul>
                          </td>
                      </tr>
                      </tbody>
                  </table>
              </div>
              <div class="cash_bottom_btn">
                  <button class="ui-button_red" type="image" @click="processWithdrawal">환전신청</button>
              </div>
              <div class="tab-pane fade" id="cash0202">
                  <div class="">
                      <div class="">
                          <table border="0" cellpadding="0" cellspacing="0" class="topth cash-list0021" width="100%">
                              <tbody>
                                <tr class="info betting_th">
                                    <th class="hidden-xs">번호</th>
                                    <th>신청날짜</th>
                                    <th>환전금액</th>
                                    <th class="hidden-xs">예금주</th>
                                    <th>상태</th>
                                    <th>삭제</th>
                                </tr>
                                <tr v-for="(row, index) in lists" :key="'withdrawalList'+index">
                                  <td>{{ (( payload.page -1 ) * payload.size) + (index + 1)  }}</td>
                                  <td>{{ row.cash_regdatetime | formatDate('YYYY-MM-DD') }}</td>
                                  <td>{{ row.cash_amount | makeComma }}</td>
                                  <td>{{ row.cash_ownername }}  |  {{ row.cash_account | strMasking }}</td>
                                  <td>{{ row.cash_status }}</td>
                                </tr>
                              </tbody>
                          </table>


                          <!-- 실제 레이아웃 -->
                      </div>


                  </div>
              </div>
          </div>
          <table width="100%" border="0" cellspacing="0" cellpadding="0" class="bank-time-table">
              <tbody>
              <tr>
                  <th>국민은행</th>
                  <td>00:00 ~ 00:10</td>
                  <th>우리은행</th>
                  <td>00:00 ~ 00:10</td>
                  <th>신한은행</th>
                  <td>00:00 ~ 00:10</td>
              </tr>
              <tr>
                  <th>농협</th>
                  <td>23:55 ~ 00:35</td>
                  <th>축협</th>
                  <td>00:00 ~ 00:10</td>
                  <th>기업은행</th>
                  <td>00:00 ~ 00:30</td>
              </tr>
              <tr>
                  <th>하나은행</th>
                  <td>00:00 ~ 00:10</td>
                  <th>우체국</th>
                  <td>04:00 ~ 05:00</td>
                  <th>SC제일</th>
                  <td>11:40 ~ 00:40</td>
              </tr>
              <tr>
                  <th>외환은행</th>
                  <td>23:55 ~ 00:05</td>
                  <th>시티은행</th>
                  <td>23:40 ~ 00:05</td>
                  <th>수협</th>
                  <td>11:50 ~ 00:30</td>
              </tr>
              <tr>
                  <th>신협</th>
                  <td>23:50 ~ 00:10</td>
                  <th>새마을금고</th>
                  <td>00:00 ~ 00:30</td>
                  <th>상호저축은행</th>
                  <td>23:00 ~ 08:00</td>
              </tr>
              <tr>
                  <th>경기은행</th>
                  <td>23:40 ~ 00:50</td>
                  <th>경남은행</th>
                  <td>23:50 ~ 00:00</td>
                  <th>광주은행</th>
                  <td>00:00 ~ 00:20</td>
              </tr>
              <tr>
                  <th>부산은행</th>
                  <td>00:00 ~ 00:05</td>
                  <th>제주은행</th>
                  <td>00:00 ~ 00:10</td>
                  <th>전북은행</th>
                  <td>23:50 ~ 00:05</td>
              </tr>
              <tr>
                  <th>대구은행</th>
                  <td>23:50 ~ 00:50</td>
                  <th>미래에셋</th>
                  <td>23:30 ~ 00:30</td>
                  <th>삼성증권</th>
                  <td>23:50 ~ 00:10</td>
              </tr>
              <tr>
                  <th>한화투자</th>
                  <td>01:45 ~ 02:15</td>
                  <th>SK증권</th>
                  <td>23:50 ~ 06:00</td>
                  <th>현대증권</th>
                  <td>23:45 ~ 00:15</td>
              </tr>
              </tbody>
          </table>
      </div>
      <div class="clear"></div>
  </div>
</template>

<script>
// import SideBox from '../components/SideBox'
// import Loading from '../components/Loading'
import MSG from '@/contants/msg';
import {mapState} from "vuex";

export default {
  name: "Withdrawal",
  components: {
    // Loading
    // SideBox
  },
  data: function(){
    return {
      amount: 0,
      account: '',
      showReq: false,
      showList: false,
      showDescription: false,
      processing: false,
      withdrawalPw: null,
      exchangeForm: {
        amount: 0,
        withdrawalPw: null,
      },
      payload: {
        page: 1,
        size: 10,
        type: '출금',
        detailType: '캐시'
      },
      pagination: {}
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      lists: state => state.user.withdrawalHistory
    }),
    WITHDRAWAL_COMMENT: function (){
      return this.$store.getters["WITHDRAWAL_COMMENT"]
    },
  },
  watch: {
    exchangeForm: {
      deep: true,
      handler(val) {
          if (val.amount > this.user.members_cash) {
          alert('보유금을 초과할 수 없습니다.')
          this.exchangeForm.amount = this.user.members_cash;
          this.amountRound();
          }
       }
    }
  },
  mounted: function() {
    window.scrollTo(0,0);
    this.reqData()
  },
  methods: {
    reqData: function(){
      this.$store.dispatch('WITHDRAWAL_HISTORY', { payload: this.payload })
          .catch(err => {
            alert(err.response.data.error);
          })
    },
    // 환전 금액 변경
    changeAmount: function(amount){
      this.exchangeForm.amount += amount;
    },
    resetAmount: function(){
      this.exchangeForm.amount = 0;
    },
    // 환전신청
    processWithdrawal: function(){
      if (this.exchangeForm.amount < 10000) return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.minProcessAmount);
      if (!this.exchangeForm.withdrawalPw || this.exchangeForm.withdrawalPw === '') return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.emptyPassword);
      this.processing = true;
      this.$store.dispatch('PROCESS_WITHDRAW', { payload: this.exchangeForm })
        .then(() => {
          this.processing = false;
          this.$store.dispatch('ME')
          this.$store.dispatch('SHOW_ERR_MSG', MSG.success.withdrawal);
        })
        .catch((err) => {
          this.processing = false;
          this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
        })
    },
    amountRound: function(){
      let result = Math.floor(this.exchangeForm.amount / 10000)
      this.exchangeForm.amount = result * 10000
    },
    movePage: function(page){
      this.$router.push({ path: `/${page}`})
    },
    toggleReq: function(){
      this.showReq = !this.showReq;
    },
    toggleList: function(){
      this.showList = !this.showList;
    },
    makeComma: function(str){
      if (!str) return 0;
      return String(str).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
}
</script>

<style scoped>
.btnDel {
    line-height: 25px !important;
    height: 25px !important;
}

.cash-list0021 td {
    border-bottom: solid 1px #444;
}

</style>
