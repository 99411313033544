<template>
    <div class="sub_content_arr">
        <div class="subTitle_arr">
            <h2>슬롯게임 <small>slot game</small></h2>
        </div>

        <div class="real_list" v-if="!vendor">

            <!---------------------------- 슬롯 리스트 ------------------------------>

            <a class="gameitem swipeitem" @click="selectVendor('PragmaticPlay')">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_7.png">
                    <div class="game_title">프라그마틱슬롯</div>
                </div>
            </a>
            <!-- <a href="lobby.asp?view=slotview&amp;thirdpartycode=52&amp;bt_type=775" class="gameitem swipeitem">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_52.png">
                    <div class="game_title">프라그마틱슬롯2</div>
                </div>
            </a> -->
            <a @click="selectVendor('Booongo')" class="gameitem swipeitem">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_9.png">
                    <div class="game_title">부운고</div>
                </div>
            </a>
            <!-- <a href="lobby.asp?view=slotview&amp;thirdpartycode=37&amp;bt_type=760" class="gameitem swipeitem">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_37.png">
                    <div class="game_title">PG소프트</div>
                </div>
            </a> -->
            <a class="gameitem swipeitem" @click="selectVendor('Wazdan')">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_17.png">
                    <div class="game_title">와즈단</div>
                </div>
            </a>
            <a class="gameitem swipeitem" @click="selectVendor('Habanero')">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_25.png">
                    <div class="game_title">하바네로</div>
                </div>
            </a>
            <!-- <a href="lobby.asp?view=slotview&amp;thirdpartycode=34&amp;bt_type=758" class="gameitem swipeitem">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_34.png">
                    <div class="game_title">트리플프로필</div>
                </div>
            </a> -->
            <!-- <a href="lobby.asp?view=slotview&amp;thirdpartycode=40&amp;bt_type=761" class="gameitem swipeitem">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_40.png">
                    <div class="game_title">블루프린트</div>
                </div>
            </a>
            <a href="lobby.asp?view=slotview&amp;thirdpartycode=41&amp;bt_type=762" class="gameitem swipeitem">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_41.png">
                    <div class="game_title">썬더킥</div>
                </div>
            </a>
            <a href="lobby.asp?view=slotview&amp;thirdpartycode=42&amp;bt_type=763" class="gameitem swipeitem">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_42.png">
                    <div class="game_title">노리밋시티</div>
                </div>
            </a>
            <a href="lobby.asp?view=slotview&amp;thirdpartycode=43&amp;bt_type=764" class="gameitem swipeitem">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_43.png">
                    <div class="game_title">모빌롯츠</div>
                </div>
            </a>
            <a href="lobby.asp?view=slotview&amp;thirdpartycode=44&amp;bt_type=765" class="gameitem swipeitem">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_44.png">
                    <div class="game_title">플레이펄스</div>
                </div>
            </a>
            <a href="lobby.asp?view=slotview&amp;thirdpartycode=45&amp;bt_type=766" class="gameitem swipeitem">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_45.png">
                    <div class="game_title">드라군소프트</div>
                </div>
            </a> -->
            <a @click="selectVendor('CQ9')" class="gameitem swipeitem">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_4.png">
                    <div class="game_title">시큐9</div>
                </div>
            </a>
            <!-- <a href="lobby.asp?view=slotview&amp;thirdpartycode=3&amp;bt_type=750" class="gameitem swipeitem">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_3.png">
                    <div class="game_title">에보플레이</div>
                </div>
            </a> -->
            <!-- <a href="lobby.asp?view=slotview&amp;thirdpartycode=24&amp;bt_type=755" class="gameitem swipeitem">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_24.png">
                    <div class="game_title">플레이스타</div>
                </div>
            </a>
            <a href="lobby.asp?view=slotview&amp;thirdpartycode=50&amp;bt_type=767" class="gameitem swipeitem">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_50.png">
                    <div class="game_title">Elk</div>
                </div>
            </a>
            <a href="lobby.asp?view=slotview&amp;thirdpartycode=49&amp;bt_type=768" class="gameitem swipeitem">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_49.png">
                    <div class="game_title">1x2게이밍</div>
                </div>
            </a>
            <a href="lobby.asp?view=slotview&amp;thirdpartycode=26&amp;bt_type=769" class="gameitem swipeitem">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_26.png">
                    <div class="game_title">플레이엔고</div>
                </div>
            </a> -->
            <a @click="selectVendor('Playson')" class="gameitem swipeitem">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_23.png">
                    <div class="game_title">플레이슨</div>
                </div>
            </a>
            <a @click="selectVendor('MicroGaming')" class="gameitem swipeitem">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_55.png">
                    <div class="game_title">마이크로게이밍슬롯</div>
                </div>
            </a>
            <a @click="selectVendor('redtiger')" class="gameitem swipeitem">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_22.png">
                    <div class="game_title">레드타이거</div>
                </div>
            </a>
            <a @click="selectVendor('netent')" class="gameitem swipeitem">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_20.png">
                    <div class="game_title">넷엔트</div>
                </div>
            </a>
            <a @click="selectVendor('BigTimeGaming')" class="gameitem swipeitem">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_56.png">
                    <div class="game_title">빅타임게이밍</div>
                </div>
            </a>
            <!-- <a href="lobby.asp?view=slotview&amp;thirdpartycode=6&amp;bt_type=776" class="gameitem swipeitem">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_6.png">
                    <div class="game_title">YGG</div>
                </div>
            </a>
            <a href="lobby.asp?view=slotview&amp;thirdpartycode=58&amp;bt_type=777" class="gameitem swipeitem">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_58.png">
                    <div class="game_title">아바타UX</div>
                </div>
            </a> -->
            <a @click="selectVendor('Hacksaw')" class="gameitem swipeitem">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_59.png">
                    <div class="game_title">핵쏘</div>
                </div>
            </a>
            <a class="gameitem swipeitem" @click="selectVendor('Playtech')">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_61.png">
                    <div class="game_title">플레이테크슬롯</div>
                </div>
            </a>
            <!-- <a href="lobby.asp?view=slotview&amp;thirdpartycode=64&amp;bt_type=780" class="gameitem swipeitem">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_64.png">
                    <div class="game_title">스카이윈드슬롯</div>
                </div>
            </a>
            <a href="lobby.asp?view=slotview&amp;thirdpartycode=65&amp;bt_type=781" class="gameitem swipeitem">
                <div class="border"><img class="game_logo" src="../../../assets/images/slot/slot_65.png">
                    <div class="game_title">온리플레이</div>
                </div>
            </a> -->
        </div>
        <div class="section" v-else>
            <ul class="submenu">

                <li v-for="row in lists" :key="'game'+row.game_id" @click="openCasino(row.game_id)">
                    <div class="companys-title code7 hvr-wobble-vertical	hvr-outline-in login-modal" data-thirdpartycode="7"
                        data-gamecode="vs20olympx">
                        <img :src="row.thumbnail" class="slot_list" data-thirdpartycode="7" data-gamecode="vs20olympx">
                        <span>{{ row.name_kr || row.name_en }}	</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
//import "swiper/css/swiper.css";
//import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { mapState } from "vuex";
import MSG from "@/contants/msg";

export default {
  name: "Slot",
  props: ['isMobile'],
  components: {
    //Swiper,
    //SwiperSlide,
  },
  data: function () {
    return {
      lists: [],
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 10,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      slotGameList: [],
      selectedCategory: null,
      extention: [],
      bookMarkedGameList: [],
      selectedGameList: [],
      originList: [],
      searchText: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
    menuVisible(){
      return this.$store.getters['MENU_VISIBLE'];
    },
    vendor: function(){ 
      return this.$route.query.vendor 
    },

  },
  mounted() {
    if (!this.menuVisible.find(e => e.name === '슬롯게임').visible){
      return this.$store.dispatch('SHOW_ERR_MSG3', '슬롯게임은 점검중입니다.');
    }
    // this.checkMemberType();
    this.goToTop();
    if (this.vendor) {
      this.reqData(this.vendor);
      this.lists = this.gameList;
    }
  },
  watch:{
    vendor: function(val){
      if (!val) return;
      this.reqData(val);
      this.lists = this.gameList;
    },
    searchText: function(val){
      this.lists = this.originList.filter((game) => game.name_en.indexOf(val) >=0 || game.name_kr.indexOf(val) >=0)
    }
  },
  methods: {
    reqData: function (vendor) {
      this.$store
        .dispatch("GET_SLOT", { payload: { sitename: "day", vendor } })
        .then((res) => {
          const data = res.data;
          console.log(data.payload);
          this.originList = data.payload.list;
          this.lists = this.originList;
        });
    },
    selectVendor: function (vendor) {
      this.$router.push({ name: '카지노-슬롯', query: { vendor: vendor }})
    },
    changeMobileCategory: function (category) {
      this.extention = false;
      this.selectedGameList = this.liveCasinoGameList.find(liveCasinoGame=> liveCasinoGame.name === category)
    },
    openCasino: function (game_id) {
      if (!this.user) return alert(MSG.isNotLogin);

      const data = {
        payload: {
          game_id,
        },
      };
      this.$store
        .dispatch("GET_GAME", data)
        .then((data) => {
          const result = data.data;
          console.log("리절트", result);
          if (result.success) {
            window.open(result.payload.link.link, "Casino", "_blank");
          } else {
            alert(result.payload.msg);
          }
        })
        .catch((err) => {
          alert(err.response.data.error);
        });
    },
    movePage: function (page) {
      this.$router.push({ path: `/${page}` });
    },
    goToTop: function () {
      window.scrollTo(0, 0);
    },
    extendList: function (index) {
      this.extention.push(index)
      // this.extention = true
    },
    checkMemberType: function () {
      if(this.user.members_type === '정산제외회원') return
      else {
        alert('카지노는 점검 중입니다.')
        this.$router.push({ path: '/main' })
      }
    }
  },
};
</script>

<style scoped>
</style>
