<template>
  <div class="sub_content_arr">
      <div class="subTitle_arr">
          <h2>충전하기 <small>charge</small></h2>
      </div>

      <div class="cont_back_arr01">
          <div class="cash_notice_zone ">

              <div class="title">충전방법</div>
              <ul>
                  <li>※ 입금순서<br>
                      <br>
                      <span style="color:#61FFFF;">계좌요청 &gt; 입금 &gt; 입금신청 </span> 순으로 해주시면 가장 신속하게 처리됩니다 (순서를 지키지 않으시면
                      입금처리되지않습니다)<br>
                      <br>
                      ※ 충전은 <span style="color:#61FFFF;">만원 단위 </span>만 가능하시며, <span style="color:#61FFFF;">천원 단위</span>
                      입금 시 <span style="color:#61FFFF;">만원 단위 맞춰서 추가 입금</span> 또는 <span style="color:#61FFFF;">이체 확인증을 텔레그램으로 제출</span>
                      하셔야 처리 가능합니다. <br>
                      <br>
                      ※ <span style="color:#61FFFF;">허위충전 </span> 시 경고없이 제재 됩니다.<br>
                      <br>
                      ※ 입금신청 후 최대 5분이내 입금이 완료되지 않을 경우 자동 취소 되며, <span style="color:#61FFFF;">반복적인 허위 입금 신청 시 경고 없이 모든 이벤트 참여 자격 박탈</span>됩니다.<br>
                      <br>
                      ※ 회원가입 시 기재된<span style="color:#61FFFF;"> 본인 명의 계좌만 입금이 가능 </span>합니다.<br>
                      <br>
                      ※ 최소 충전 금액은 <span style="color:#61FFFF;">10,000 원 </span>입니다.
                  </li>
              </ul>
          </div>
          <div class="cashzone_talbe_arr">
              <table width="100%" cellspacing="0" cellpadding="0" class="cash_zone_table ">
                  <tbody>
                  <tr>
                      <th>입금하실계좌</th>
                      <td id="bank_info" align="left" style="padding:10px; ">
                        ① <b>로그인 비밀번호 입력<b> 
                          <input name="passwd"
                          type="password"
                          class="bg_input"
                          id="passwd"
                          size="25"
                          maxlength="20">
                          <span id="btnSubmitZone">
                            <button type="button" value="계좌확인" id="btnBankAct" class="ui_btn_green"
                                                          style="cursor: pointer;">계좌확인</button></span></b></b></td>
                  </tr>

                  <tr>
                      <th>포인트선택</th>
                      <td style="line-height: 25px; padding: 10px;">
                          <label v-for="b in bonus" :key="'rolling_bonus' + b.seq">
                            <input type="radio" name="point_type" :value="b.seq" v-model="chargeForm.rollingSeq"> 
                            {{ b.name }} ({{ b.description }})
                          </label>
                          <br>
                      </td>
                      <td>
                      </td>
                  </tr>

                  <tr>
                      <th>충전금액</th>
                      <td align="left" style="padding:10px; ">
                          <input name="point" type="text" class="bg_input money" id="point" size="25" v-model="amount"
                                style="text-align: right; padding-right: 5px;">
                          원 &nbsp;&nbsp;
                          &nbsp;&nbsp; 
                          <a @click="changeAmount(10000)" class="ui_btn_gray" style="cursor:pointer;"> 1만원</a> 
                          <a @click="changeAmount(30000)" class="ui_btn_gray" style="cursor:pointer;"> 3만원</a> 
                          <a @click="changeAmount(50000)" class="ui_btn_gray" style="cursor:pointer;">5만원</a>
                          <a @click="changeAmount(100000)" class="ui_btn_gray" style="cursor:pointer;">10만원</a>
                          <a @click="changeAmount(200000)" class="ui_btn_gray" style="cursor:pointer;">20만원</a> 
                          <a @click="changeAmount(300000)" class="ui_btn_gray" style="cursor:pointer;">30만원</a>
                          <a @click="changeAmount(500000)" class="ui_btn_gray" style="cursor:pointer;">50만원</a>
                          <a @click="changeAmount(1000000)" class="ui_btn_gray">100만원</a>
                          <a @click="changeAmount(5000000)" class="ui_btn_gray">500만원</a>
                          <a @click="changeAmount(10000000)" class="ui_btn_gray">1000만원</a>

                          <a @click="resetAmount" class="ui_btn_blue" style="cursor:pointer;">정정</a></td>
                  </tr>
                  <tr>
                      <th>입금자명</th>
                      <td align="left" style="padding:10px; ">
                          <input name="name" type="text" class="bg_input" id="name" size="25"
                                readonly="" >
                      </td>
                  </tr>
                  </tbody>
              </table>
          </div>
          <div class="cash_bottom_btn">
              <button class="ui-button_red" type="image" @click="processDeposit">충전신청</button>
          </div>
          <div class="tab-pane fade" id="cash0102">
              <div class="">
                  <table border="0" cellpadding="0" cellspacing="0" width="100%" class="topth cash-list0021">
                      <tbody>
                      <tr class="info betting_th">
                          <th class="hidden-xs">번호</th>
                          <th>신청날짜</th>
                          <th>충전금액</th>
                          <th class="hidden-xs">입금자명</th>
                          <!-- <th class="hidden-xs">충전/환급 포인트</th> -->
                          <th>상태</th>
                          <th>삭제</th>
                      </tr>
                      <tr v-for="(row, index) in lists" :key="'depositList'+index">
                        <td>{{ (( payload.page -1 ) * payload.size) + (index + 1)  }}</td>
                        <td>{{ row.cash_regdatetime | formatDate('YYYY-MM-DD') }}</td>
                        <td>{{ row.cash_amount | makeComma }}</td>
                        <td>{{ row.cash_ownername }}</td>
                        <td>{{ row.cash_status }}</td>
                        <td>
                          <button v-if="row.cash_status !== '접수'" class="btnReq04" @click="deleteDeposit(row)">삭제</button>
                        </td>
                      </tr>
                      </tbody>
                  </table>
                  <!-- <div class="text-center">
                      <div class="tf_pagination">
                          <ul>
                              <li>
                                <a id="pre-btn" href="javascript:void(0);">
                                  <i class="fa fa-chevron-left" aria-hidden="true"></i>
                                </a>
                              </li>
                              <li><a class="active">1</a></li>
                              <li><a id="next-btn" href="javascript:void(0);"><i class="fa fa-chevron-right"
                                                                                aria-hidden="true"></i></a></li>
                          </ul>
                      </div>
                  </div> -->
              </div>


          </div>
          <table width="100%" border="0" cellspacing="0" cellpadding="0" class="bank-time-table">
              <tbody>
              <tr>
                  <th>국민은행</th>
                  <td>00:00 ~ 00:10</td>
                  <th>우리은행</th>
                  <td>00:00 ~ 00:10</td>
                  <th>신한은행</th>
                  <td>00:00 ~ 00:10</td>
              </tr>
              <tr>
                  <th>농협</th>
                  <td>23:55 ~ 00:35</td>
                  <th>축협</th>
                  <td>00:00 ~ 00:10</td>
                  <th>기업은행</th>
                  <td>00:00 ~ 00:30</td>
              </tr>
              <tr>
                  <th>하나은행</th>
                  <td>00:00 ~ 00:10</td>
                  <th>우체국</th>
                  <td>04:00 ~ 05:00</td>
                  <th>SC제일</th>
                  <td>11:40 ~ 00:40</td>
              </tr>
              <tr>
                  <th>외환은행</th>
                  <td>23:55 ~ 00:05</td>
                  <th>시티은행</th>
                  <td>23:40 ~ 00:05</td>
                  <th>수협</th>
                  <td>11:50 ~ 00:30</td>
              </tr>
              <tr>
                  <th>신협</th>
                  <td>23:50 ~ 00:10</td>
                  <th>새마을금고</th>
                  <td>00:00 ~ 00:30</td>
                  <th>상호저축은행</th>
                  <td>23:00 ~ 08:00</td>
              </tr>
              <tr>
                  <th>경기은행</th>
                  <td>23:40 ~ 00:50</td>
                  <th>경남은행</th>
                  <td>23:50 ~ 00:00</td>
                  <th>광주은행</th>
                  <td>00:00 ~ 00:20</td>
              </tr>
              <tr>
                  <th>부산은행</th>
                  <td>00:00 ~ 00:05</td>
                  <th>제주은행</th>
                  <td>00:00 ~ 00:10</td>
                  <th>전북은행</th>
                  <td>23:50 ~ 00:05</td>
              </tr>
              <tr>
                  <th>대구은행</th>
                  <td>23:50 ~ 00:50</td>
                  <th>미래에셋</th>
                  <td>23:30 ~ 00:30</td>
                  <th>삼성증권</th>
                  <td>23:50 ~ 00:10</td>
              </tr>
              <tr>
                  <th>한화투자</th>
                  <td>01:45 ~ 02:15</td>
                  <th>SK증권</th>
                  <td>23:50 ~ 06:00</td>
                  <th>현대증권</th>
                  <td>23:45 ~ 00:15</td>
              </tr>
              </tbody>
          </table>
      </div>

  </div>
</template>

<script>
// import SideBox from '../components/SideBox'
// import Loading from '../components/Loading'
import {mapState} from "vuex";
import MSG from '@/contants/msg'

export default {
  name: "Deposit",
  components: {
    // Loading,
    // SideBox
  },
  data: function(){
    return {
      amount: 0,
      account: '',
      ownerName: '',
      bankName: '',
      showReq: false,
      showList: false,
      showDescription: false,
      processing: false,
      bonus: [],
      chargeForm: {
        amount: 0,
        serviceType: 'n',
        code: null,
        rollingSeq: null,
      },
      payload: {
        page: 1,
        size: 10,
        type: '입금',
        detailType: '캐시',
      },
      showCodeText: null,
      pagination: {},

    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      lists: state => state.user.depositHistory
    }),
    DEPOSIT_COMMENT: function (){
      return this.$store.getters["DEPOSIT_COMMENT"]
    },
  },
  mounted: function() {
    window.scrollTo(0,0);
    this.reqRollingBonus();
    this.reqData()
  },
  methods: {
    reqData: function(){
      this.$store.dispatch('DEPOSIT_HISTORY', { payload: this.payload })
          .catch(err => {
            this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
          })
    },
    // 충전 금액 변경
    changeAmount: function(amount){
      this.chargeForm.amount += amount;
    },
    resetAmount: function(){
      this.chargeForm.amount = 0;
    },
    // 충전신청
    processDeposit: function(){
      if (this.chargeForm.amount < 10000) return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.minProcessAmount);
      if (this.showCodeText) return this.$store.dispatch('SHOW_ERR_MSG', '유효한 코드를 입력해주세요.');
      this.processing = true;
      this.$store.dispatch('PROCESS_DEPOSIT', { payload: this.chargeForm })
          .then(() => {
            this.processing = false;
            this.$store.dispatch('SHOW_ERR_MSG', MSG.success.deposit);
          })
          .catch((err) => {
            this.processing = false;
            this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
          })

    },
    amountRound: function(){
      let result = Math.floor(this.chargeForm.amount / 1000)
      this.chargeForm.amount = result * 1000
    },
    // 계좌번호 확인
    reqAccount: function() {
      return this.$store.dispatch('REQ_ACCOUNT')
          .then(data => {
            const result = data.data;
            this.account = result.payload.bank.account;
            this.ownerName = result.payload.bank.ownerName;
            this.bankName = result.payload.bank.bankName;
          }).catch(err => {
            this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
          })
    },
    reqRollingBonus: function() {
      return this.$store.dispatch('REQ_ROLLING_BONUS')
          .then(data => {
            const result = data.data;
            this.bonus = result.payload.bonus;
          }).catch(err => {
            this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
          })
    },

    movePage: function(page){
      this.$router.push({ path: `/${page}`})
    },
    toggleReq: function(){
      this.showReq = !this.showReq;
    },
    toggleDescription: function(){
      this.showDescription = !this.showDescription;
    },
    toggleList: function(){
      this.showList = !this.showList;
    },
    checkBonusCode: function(){
      if (!this.chargeForm.code || this.chargeForm.code === '') return this.showCodeText = null;
      this.$store.dispatch('CHECK_DEPOSIT_BONUS_CODE', { payload: this.chargeForm })
          .then((data) => {
            const result = data.data;
            if (!result.success) this.showCodeText = result.msg;
            if(result.success) {
              this.chargeForm.serviceType = 'y';
              this.showCodeText = null;
            }
          })
    }
  }
}
</script>

<style scoped>

.btnDel {
  line-height: 25px !important;
  height: 25px !important;
}

.cash-list0021 td {
  border-bottom: solid 1px #444;
}


input[type='text'], input[type='password'], select {
    border: 1px solid #444;
    background: #111;
    color: #fff;
    font-size: 12px;
    padding: 2px 5px;
    line-height: 33px;
    height: 33px;
    transition: 0.2s;
    vertical-align: middle;
}
textarea {
    border: 1px solid #444;
    background: #111;
    color: #fff;
    font-size: 12px;
    padding: 2px 5px;
    transition: 0.2s;
}
textarea:focus {
    outline: none;
    border: solid 1px #ffcc00;
}
select {
    height: 38px;
    line-height: 38px;
}
input[type='text']:focus, input[type='password']:focus, select:focus, textarea:focus {
    outline: none;
    border: solid 1px #ffcc00;
}
</style>
