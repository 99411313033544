<template>
  <section v-if="!this.$store.state.page.isMobile" id="contents_wrap">
    <div class="contents">
      <div class="sub_left">
        <ul class="l_tabs">
<!--          <li id="ht1" class=""><a href="#l-tab1">베팅 가이드</a></li>-->
          <li id="ht5" class="active"><a href="#l-tab5">베팅 규정</a></li>
<!--          <li id="ht2"><a href="#l-tab2">게임 가이드</a></li>-->
<!--          <li id="ht3" class=""><a href="#l-tab3">자주 묻는 질문</a></li>-->
<!--          <li id="ht4" class=""><a href="#l-tab4">이용약관</a></li>-->
        </ul>
      </div>
      <div class="sub_right" v-if="isLoading">
        <template v-for="(row, index) in lists">
          <div id="l-tab1" class="left_tab_con" style="display: block;" :key="'rule'+index">
            <ul class="smk_accordion" style="display: block;">
              <li class="acc_section acc_active"  @click="toggleDetail(index)">
                <div class="acc_head br1" ><h3>{{ row.board_title }}</h3></div>
                <transition name="fade">
                  <div class="acc_content" style="display: block;" v-if="openDetail.indexOf(index) >= 0">
                    <div class="acc_content_in_2" v-html="renderHtml(row.board_content)"></div>
                  </div>
                </transition>
              </li>

            </ul>
          </div>
        </template>
      </div>
      <div class="sub_right" style="text-align: center;" v-else>
        <Loading></Loading>
      </div>
    </div>

  </section>
  <div v-else>
    <div class="header2">
      <h2>베팅 규정</h2>
    </div>
    <div class="tabs1">
      <!-- <router-link to="bbs/n/l">공지사항</router-link>
      <router-link to="bbs/e/l">이벤트</router-link> -->
      <router-link class="active" to="bbs/rule/l">베팅규정</router-link>
    </div>

    <div class="bg-content">
      <div class="notice">
        <ul>
          <li v-if="!lists">
            <div style="text-align: center;">
              <Loading></Loading>
            </div>
          </li>
          <li v-if="lists && lists.length === 0">
            <a class="q">
              <span>
                작성된 글이 없습니다.
              </span>
            </a>
          </li>
          <li v-else v-for="(row, index) in lists" :key="'customer'+index">
            <a @click="toggleDetail(index)" class="q">
              <span>
                {{ row.board_title }}
              </span>
              <!-- <em>{{ row.board_regdatetime | formatDate('YYYY-MM-DD') }}</em> -->
            </a>
            <transition name="fade">
              <div class="cont" v-html="renderHtml(row.board_content)" v-if="checkArray(index)" style="display: block"></div>
            </transition>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '../../../components/LoadingGray'

export default {
  name: "Rule",
  components: {
    Loading,
  },
  data: function(){
    return {
      lists: null,
      openDetail: [0],
      isLoading: false,
      payload: {
        page: 1,
        size: 100,
        keyword: null,
        type: 'rule',
        sitename: 'casino'
      },
    }
  },
  mounted() {
    this.reqData(this.payload)
  },
  methods: {
    reqData: function(payload){
      this.isLoading = false;
      return this.$store.dispatch('GET_BOARD', { payload })
          .then(data => {
            const result = data.data;
            this.lists = result.payload.list;
            this.isLoading = true;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    movePage: function(page){
      this.$router.push({path: `/${page}`})
    },
    checkArray: function (id) {
      return this.openDetail.indexOf(id) >= 0
    },
    toggleDetail: function (id) {
      this.toggle(this.openDetail, id)
    },
    removeAtIndex: function (arr, index) {
      // const copy = [...arr];
      this.openDetail.splice(index, 1);
      // return this.openDetailBet;
    },
    toggle: function (arr, item, getValue = item => item) {
      const index = arr.findIndex(i => getValue(i) === getValue(item));
      if (index === -1) {
        this.openDetail = [...arr, item]
        return;
      }

      return this.removeAtIndex(arr, index);
    },

    // 모바일 적용
    renderHtml: function(str){
      str.replace('<img', '<img @click="checkTest(1);" ')
      str.replace(/(?:\r\n|\r|\n)/g, '<br />')
      return str.split('\n').join('<br />');
    },
    checkTest: function(t){
      console.log(t);
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}

/*-------------------------------------------------------------------------------------*
 *  Contents                                                                           *
 *-------------------------------------------------------------------------------------*/
#contents_wrap {float:left; width:100%; padding:30px 0 50px 0 ;}
.contents {width:1330px; margin:0px auto; min-height:600px; }

.sub_left {width:260px; float:left; margin:0 10px 0 0}
.sub_right {width:1060px; float:left;}
.l_tabs {width:100%; float:left;}
.l_tabs li{ width:240px; padding:0 0 0 20px; margin:0 0 1px 0;height:40px;background:#262a2b;  line-height:40px;}
.l_tabs li a {width:100%;display:inline-block;}
.l_tabs li:hover{background:#2a2e2f}
ul.l_tabs li.active a {color:#ffffff}
ul.l_tabs li.active, ul.l_tabs li.active a:hover {background: #041a41; }
.left_tab_con {width:100%; }

/* accordion */
.smk_accordion { width:100%; position: relative; }
.smk_accordion .acc_section .acc_head {position: relative;background: #2d3232;padding:10px 0 10px 0;display: block;cursor: pointer; width:100%; text-indent:10px}
.smk_accordion .acc_section .acc_head h3 {line-height: 1;margin: 5px 0; font-size:14px; font-weight:700}
.smk_accordion .acc_section .acc_content {background:#191a1b; }
.smk_accordion .acc_section.acc_active > .acc_head {background: #222727;}
.smk_accordion li{margin:0 0 1px 0}
.acc_content_in_1 {padding:60px; background:#191a1b;}
.acc_content_in_2 {padding:40px; background:#191a1b; }
.acc_btn_wrap {width:100%;text-align:center; margin:20px 0 0 0 }
</style>
