<template>
  <div id="footer">
    <span class="banner-btomm"></span>
    <div class="service_txt">
      <img src="../assets/images/f_partners.png">
    </div>
    <br><br>
    <div class="copyright_txt">
      <p>Copyright ⓒ 업카지노. All rights reserved.</p>
    </div>
    <br><br>
    <div class="clear"></div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods:{
  }
}
</script>

<style scoped>
  #footer { position: relative;     background: #232426;  text-align: center;}    
  #footer .logo_img {    margin-top: 0px; margin-bottom: 0px; width: 100px;}
  #footer .centerbox {  width: 100%; display: block;}
  .service_txt { padding-top: 40px;color:#fff}
  #footer img { margin: 10px;}
  .banner-btomm { text-align: center; padding-top: 20px;  display: block; }
	.banner-btomm img { width: 22%}
  .copyright_txt{color:#fff}  
</style>
