<template>
  <div class="sub_content_arr">
      <div>
          <form name="frm" id="frm" action="/event.asp" method="post">
              <input type="hidden" name="page" value="1">
              <input type="hidden" name="code_group" value="65">
              <input type="hidden" name="code_no" value="65">
              <input type="hidden" name="code" value="4">
              <div class="subTitle_event"><h2>이벤트 <small>event</small></h2></div>
              <!-- 서브타이틀 -->
              <ul class="tabs">
                  <li class="active li_tot" rel="tab1">전체 (1)</li>
                  <li rel="tab2"></li>
                  <li rel="tab3"></li>
                  <li rel="tab4"></li>
                  <li rel="tab5"></li>
                  <li rel="tab6"></li>
                  <li rel="tab7"></li>
                  <li rel="tab8"></li>
                  <li rel="tab9"></li>
                  <li rel="tab10"></li>
                  <li rel="tab"></li>
              </ul>
              <div class="tab_container">
                  <div id="tab1" class="tab_content" style="display: block;">
                      <ul class="event_listv">
                          <li category="전체"><a href="javascript:void(0)">
                              <div class="upZone"><img src="//i.imgur.com/10QpAq9.png"></div>
                              <div class="bottomZone"><h3 class="txtFrame">복귀자 누적금액 이벤트</h3>                  <strong
                                      class="goPromotion" seq="89727">상세보기</strong></div>
                          </a></li>
                          <li category="전체"><a href="javascript:void(0)">
                              <div class="upZone"><img src="//i.imgur.com/QmjJzvq.png"></div>
                              <div class="bottomZone"><h3 class="txtFrame"><font size="2" color="White" face="고딕"> <font
                                      color="#61FFFF"> ★ </font> 복귀자 무한입플 이벤트 <font color="#61FFFF"> ★ </font></font></h3>
                                  <strong class="goPromotion" seq="88150">상세보기</strong></div>
                          </a></li>
                          <li category="전체"><a href="javascript:void(0)">
                              <div class="upZone"><img src="//i.imgur.com/PIGZMYL.png"></div>
                              <div class="bottomZone"><h3 class="txtFrame"><font size="2" color="White" face="고딕"> <font
                                      color="#61FFFF"> ★ </font> 복귀자 페이백 상향 이벤트 <font color="#61FFFF"> ★ </font></font>
                              </h3>                  <strong class="goPromotion" seq="88148">상세보기</strong></div>
                          </a></li>
                          <li category="전체"><a href="javascript:void(0)">
                              <div class="upZone"><img src="//i.imgur.com/zUjhrsb.png"></div>
                              <div class="bottomZone"><h3 class="txtFrame"><font size="2" color="White" face="고딕"> <font
                                      color="#61FFFF"> ★ </font> 일일 누적 충전 횟수 이벤트 <font color="#61FFFF"> ★ </font></font>
                              </h3>                  <strong class="goPromotion" seq="80992">상세보기</strong></div>
                          </a></li>
                          <li category="전체"><a href="javascript:void(0)">
                              <div class="upZone"><img src="//i.imgur.com/xugOgn5.jpeg"></div>
                              <div class="bottomZone"><h3 class="txtFrame"><font size="2" color="White" face="고딕"> <font
                                      color="#61FFFF"> ★ </font> 입금플러스 무한도전 이벤트 <font color="#61FFFF"> ★ </font></font>
                              </h3>                  <strong class="goPromotion" seq="80025">상세보기</strong></div>
                          </a></li>
                          <li category="전체"><a href="javascript:void(0)">
                              <div class="upZone"><img src="//i.imgur.com/1mxG9KY.png"></div>
                              <div class="bottomZone"><h3 class="txtFrame"><font size="2" px;font-weight:bold;=""
                                                                                color="White" face="고딕"> <font
                                      color="#61FFFF"> ★ </font> 주말 기프티콘 이벤트 <font color="#61FFFF"> ★ </font></font></h3>
                                  <strong class="goPromotion" seq="78715">상세보기</strong></div>
                          </a></li>
                          <li category="전체"><a href="javascript:void(0)">
                              <div class="upZone"><img src="//i.imgur.com/Hu2LfVL.jpeg" title="14eventthum.png"></div>
                              <div class="bottomZone"><h3 class="txtFrame"><font size="2" px;font-weight:bold;=""
                                                                                color="White" face="고딕"> <font
                                      color="#61FFFF"> ★ </font> 출석 이벤트 <font color="#61FFFF"> ★ </font></font></h3>
                                  <strong class="goPromotion" seq="561">상세보기</strong></div>
                          </a></li>
                          <li category="전체"><a href="javascript:void(0)">
                              <div class="upZone"><img src="//i.imgur.com/An3fxwK.jpeg"></div>
                              <div class="bottomZone"><h3 class="txtFrame"><font size="2" color="White" face="고딕"> <font
                                      color="#61FFFF"> ★ </font> 신규 정착 이벤트 <font color="#61FFFF"> ★ </font></font></h3>
                                  <strong class="goPromotion" seq="14">상세보기</strong></div>
                          </a></li>
                          <li category="전체"><a href="javascript:void(0)">
                              <div class="upZone"><img src="//i.imgur.com/yMb2VQ2.jpeg"></div>
                              <div class="bottomZone"><h3 class="txtFrame"><font size="2" color="White" face="고딕"> <font
                                      color="#61FFFF"> ★ </font> 지인 실 베터 이벤트 <font color="#61FFFF"> ★ </font></font></h3>
                                  <strong class="goPromotion" seq="13">상세보기</strong></div>
                          </a></li>
                          <li category="전체"><a href="javascript:void(0)">
                              <div class="upZone"><img src="//i.imgur.com/f3tDGWn.jpeg"></div>
                              <div class="bottomZone"><h3 class="txtFrame"><font size="2" color="White" face="고딕"> <font
                                      color="#61FFFF"> ★ </font> 지인, 추천인 일석이조 <font color="#61FFFF"> ★ </font></font></h3>
                                  <strong class="goPromotion" seq="12">상세보기</strong></div>
                          </a></li>
                          <li category="전체"><a href="javascript:void(0)">
                              <div class="upZone"><img src="//i.imgur.com/YnREulF.jpeg"></div>
                              <div class="bottomZone"><h3 class="txtFrame"><font size="2" color="White" face="고딕"> <font
                                      color="#61FFFF"> ★ </font> 슬롯 매니아 이벤트 <font color="#61FFFF"> ★ </font></font></h3>
                                  <strong class="goPromotion" seq="11">상세보기</strong></div>
                          </a></li>
                          <li category="전체"><a href="javascript:void(0)">
                              <div class="upZone"><img src="//i.imgur.com/FeoLMnH.jpeg"></div>
                              <div class="bottomZone"><h3 class="txtFrame"><font size="2" color="White" face="고딕"> <font
                                      color="#61FFFF"> ★ </font> 첫 충전 이벤트 <font color="#61FFFF"> ★ </font></font></h3>
                                  <strong class="goPromotion" seq="10">상세보기</strong></div>
                          </a></li>
                          <li category="전체"><a href="javascript:void(0)">
                              <div class="upZone"><img src="//i.imgur.com/giZJ6vU.jpeg"></div>
                              <div class="bottomZone"><h3 class="txtFrame"><font size="2" color="White" face="고딕"> <font
                                      color="#61FFFF"> ★ </font> 오류제보 <font color="#61FFFF"> ★ </font></font></h3>
                                  <strong class="goPromotion" seq="8">상세보기</strong></div>
                          </a></li>
                          <li category="전체"><a href="javascript:void(0)">
                              <div class="upZone"><img src="//i.imgur.com/jPwmg8R.jpeg"></div>
                              <div class="bottomZone"><h3 class="txtFrame"><font size="2" color="White" face="고딕"> <font
                                      color="#61FFFF"> ★ </font> 업을 알리자 <font color="#61FFFF"> ★ </font></font></h3>
                                  <strong class="goPromotion" seq="7">상세보기</strong></div>
                          </a></li>
                          <li category="전체"><a href="javascript:void(0)">
                              <div class="upZone"><img src="//i.imgur.com/H9vWoOJ.jpeg"></div>
                              <div class="bottomZone"><h3 class="txtFrame"><font size="2" color="White" face="고딕"> <font
                                      color="#61FFFF"> ★ </font> 무제한 콤프 <font color="#61FFFF"> ★ </font></font></h3>
                                  <strong class="goPromotion" seq="6">상세보기</strong></div>
                          </a></li>
                          <li category="전체"><a href="javascript:void(0)">
                              <div class="upZone"><img src="//i.imgur.com/lBAOlav.jpeg"></div>
                              <div class="bottomZone"><h3 class="txtFrame"><font size="2" color="White" face="고딕"> <font
                                      color="#61FFFF"> ★ </font> 연패 이벤트 <font color="61FFFF"> ★ </font></font></h3>
                                  <strong class="goPromotion" seq="5">상세보기</strong></div>
                          </a></li>
                          <li category="전체"><a href="javascript:void(0)">
                              <div class="upZone"><img src="//i.imgur.com/QS4gd5V.jpeg"></div>
                              <div class="bottomZone"><h3 class="txtFrame"><font size="2" color="White" face="고딕"> <font
                                      color="#61FFFF"> ★ </font> 당일페이백 <font color="#61FFFF"> ★ </font></font></h3>
                                  <strong class="goPromotion" seq="4">상세보기</strong></div>
                          </a></li>
                          <li category="전체"><a href="javascript:void(0)">
                              <div class="upZone"><img src="//i.imgur.com/vnOPneB.jpeg"></div>
                              <div class="bottomZone"><h3 class="txtFrame"><font size="2" px;font-weight:bold;=""
                                                                                color="White" face="고딕"> <font
                                      color="#61FFFF"> ★ </font> 프리스핀 이벤트 <font color="#61FFFF"> ★ </font></font></h3>
                                  <strong class="goPromotion" seq="3">상세보기</strong></div>
                          </a></li>
                          <li category="전체"><a href="javascript:void(0)">
                              <div class="upZone"><img src="//i.imgur.com/4TsMy9B.jpeg"></div>
                              <div class="bottomZone"><h3 class="txtFrame"><font size="2" color="White" face="고딕"> <font
                                      color="#61FFFF"> ★ </font> 잭팟 이벤트 <font color="#61FFFF"> ★ </font></font></h3>
                                  <strong class="goPromotion" seq="2">상세보기</strong></div>
                          </a></li>
                          <li category="전체"><a href="javascript:void(0)">
                              <div class="upZone"><img src="//i.imgur.com/LfVogP1.jpeg"></div>
                              <div class="bottomZone"><h3 class="txtFrame"><font size="2" color="White" face="고딕"> <font
                                      color="#61FFFF"> ★ </font> 페이백 이벤트 <font color="#61FFFF"> ★ </font></font></h3>
                                  <strong class="goPromotion" seq="1">상세보기</strong></div>
                          </a></li>
                          <li category="전체"><a href="javascript:void(0)">
                              <div class="upZone"><img src="//i.imgur.com/74BIN0m.jpeg"></div>
                              <div class="bottomZone"><h3 class="txtFrame"><font size="2" color="White" face="고딕"> <font
                                      color="#61FFFF"> ★ </font> 텔레그램 친구 추가 <font color="#61FFFF"> ★ </font></font></h3>
                                  <strong class="goPromotion" seq="15">상세보기</strong></div>
                          </a></li>
                      </ul>
                  </div>
                  <div id="tab2" class="tab_content" style="display: none;">
                      <ul class="event_lists"></ul>
                  </div>
                  <div id="tab3" class="tab_content" style="display: none;">
                      <ul class="event_lists"></ul>
                  </div>
                  <div id="tab4" class="tab_content" style="display: none;">
                      <ul class="event_lists"></ul>
                  </div>
                  <div id="tab5" class="tab_content" style="display: none;">
                      <ul class="event_lists"></ul>
                  </div>
                  <div id="tab6" class="tab_content" style="display: none;">
                      <ul class="event_lists"></ul>
                  </div>
                  <div id="tab7" class="tab_content" style="display: none;">
                      <ul class="event_lists"></ul>
                  </div>
                  <div id="tab8" class="tab_content" style="display: none;">
                      <ul class="event_lists"></ul>
                  </div>
                  <div id="tab9" class="tab_content" style="display: none;">
                      <ul class="event_lists"></ul>
                  </div>
                  <div id="tab10" class="tab_content" style="display: none;">
                      <ul class="event_lists"></ul>
                  </div>
                  <div id="tab11" class="tab_content" style="display: none;">
                      <ul class="event_lists"></ul>
                  </div>
              </div>
              <div class="modal01">
                  <div class="modal01-cont"><i class="fa fa-times-circle-o modal01-close" aria-hidden="true"></i>
                      <div class="title"></div>
                      <div class="leftPic"></div>
                      <div class="rightTxt"></div>
                  </div>
              </div>

              <!-- 페이징 시작 -->

              <div class="clear"></div>
              <div class="paging_list">
                  <div class="tf_pagination">
                      <ul>
                          <li><a id="pre-btn" href="javascript:void(0);"><i class="fa fa-chevron-left"
                                                                            aria-hidden="true"></i></a></li>
                          <li><a class="active">1</a></li>
                          <li><a id="next-btn" href="javascript:void(0);"><i class="fa fa-chevron-right"
                                                                            aria-hidden="true"></i></a></li>
                      </ul>
                  </div>
              </div>
          </form>
          <div class="clear"></div>
      </div>
      <div class="clear"></div>
  </div>
</template>

<script>
// import Pagination from '../../../components/Pagination01'
// import Loading from '../../../components/LoadingGray'

export default {
  name: "Event",
  components: {
    // Pagination,
    // Loading
  },
  data: function(){
    return {
      path: 'bbs/e/l',
      lists: null,
      pagination: null,
      payload: {
        page: 1,
        size: 20,
        keyword: null,
        siteName: 'casino'
      },
      expandedArticle: []
    }
  },
  mounted: function() {
    this.reqData(this.payload)
  },
  methods: {
    reqData: function(payload){
      return this.$store.dispatch('GET_EVENT', { payload } )
          .then(data => {
            const result = data.data;
            this.lists = result.payload.list;
            this.pagination = result.payload.pagination;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    movePage: function(page){
      this.$router.push({path: `/${page}`})
    },
    renderHtml: function(str){
      str.replace(/(?:\r\n|\r|\n)/g, '<br />')
      return str.split('\n').join('<br />');
    },
    toggle: function (index) {
      if (this.expandedArticle.indexOf(index) >= 0) return this.expandedArticle.splice(this.expandedArticle.indexOf(index), 1)
      this.expandedArticle.push(index)
    },
    checkArray: function (id) {
      let result = this.expandedArticle.indexOf(id) >= 0
      return result
    },
  }
}
</script>

<style scoped>
</style>

