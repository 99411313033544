<template>
  <div id="id">
    <router-view></router-view>
  </div>
</template>
<script>

// import {mapState} from "vuex";

export default {
  name: 'App',
}
</script>
