<template>
  <div class="header-arr">
    <div class="header">
      <div class="hader_top_arr">
        <div class="user-info-arr1"> <a href="/login.asp" class="logo_top"><img src="../assets/images/logo.png?13"></a>
          <div class="main_navigation_arr">
            <div class="leftTitle"><i class="fi fi-tr-chess"></i> Game zone</div>
            <ul class="main_navigation">
              <li><router-link :to="{ name: '카지노' }" title="라이브카지노"><i class="fi fi-tr-spade"></i><img src="../assets/images/icon_menu01.png"> 카지노</router-link></li>
              <li><router-link :to="{ name: '슬롯' }" title="슬롯게임"><i class="fi fi-tr-cherry"></i> <img src="../assets/images/icon_menu02.png"> 슬롯게임</router-link></li>
              <!-- <li><a href="javascript:void(0)" class="login-modal"><i class="fi fi-tr-gamepad"></i> <img src="../assets/images/icon_menu03.png"> 미니게임</a></li> -->
            </ul>
            <div class="leftTitle"><i class="fi fi-tr-puzzle-alt"></i> Navigation</div>
            <ul class="main_navigation">
              <li><router-link :to="{ name: '입금'}" class="login-modal" title="충전신청"><i class="fi fi-tr-deposit"></i><img src="../assets/images/icon_menu04.png"> 입금신청</router-link></li>
              <li><router-link :to="{ name: '출금'}" class="login-modal" title="환전신청"><i class="fi fi-tr-hand-bill"></i> <img src="../assets/images/icon_menu05.png"> 출금신청</router-link></li>
              <li><a href="javascript:void(0)" class="login-modal"><i class="fi fi-tr-features"></i> <img src="../assets/images/icon_menu0011.png"> 배팅내역</a></li>
              <li><a href="javascript:void(0)" class="login-modal"><i class="fi fi-tr-ranking-stars"></i> <img src="../assets/images/icon_menu0012.png"> 경기결과</a></li>
              
              <!-- li><a href="memo.asp" title="쪽지함"><i class="fi fi-tr-envelopes"></i> 쪽지함 ( 	 )</a></li -->
              <li><a href="javascript:void(0)" class="login-modal" title="출석하기"><i class="fi fi-tr-calendar-heart"></i> <img src="../assets/images/icon_menu0013.png"> 출석하기</a></li>
              <li><a href="javascript:void(0)" class="login-modal" title="정보수정"><i class="fi fi-tr-admin-alt"></i> <img src="../assets/images/icon_menu09.png"> 마이페이지</a></li>
              <li><a href="javascript:void(0)" class="login-modal" title="추천내역"><i class="fi fi-tr-users-medical"></i> <img src="../assets/images/icon_menu0014.png"> 추천내역</a></li>
            </ul>
            <div class="leftTitle"><i class="fi fi-tr-journal-alt"></i> Other information</div>
            <ul class="main_navigation">
              <li><router-link :to="{ name: '이벤트-목록'}" class="login-modal" title="게시판"><i class="fi fi-tr-gifts"></i> <img src="../assets/images/icon_menu11.png"> 이벤트</router-link></li>
              <li><router-link :to="{ name: '공지사항-목록'}" class="login-modal" title="공지사항"><i class="fi fi-tr-bells"></i> <img src="../assets/images/icon_menu12.png"> 공지사항</router-link></li>
              <li><a href="javascript:void(0)" class="login-modal"><i class="fi fi-tr-user-headset"></i> <img src="../assets/images/icon_menu13.png"> 고객센터</a></li>
            </ul>
          </div>
        </div>
        <div id="popup-zone"> </div>
        <img src="../assets/images/over19.png" style="margin: 20px auto; display: block; text-align: center;">
      </div>
      
      
      
      
      
      
      
      
      </div>
  </div>
</template>

<script>
// import CurrentTime from '@/components/CurrentTime';
import {mapState} from "vuex";
import MSG from "@/contants/msg";
// import Loading from '@/components/Loading'
// import CurrentLanguage from '@/components/CurrentLanguage';

export default {
  name: "Header",
  components: {
    // CurrentTime,
    // Loading,
    // CurrentLanguage
  },
  props:['currentMenu'],
  data(){
    return {
      navHeader: false,
      toggle: false,
      toggle2: false,
      signInForm: {
        userId: null,
        userPw: null,
        sitename: 'casino',
        version: null,
      },
      subMenu: '',
      proceeding: false,
    }
  },
  computed: {
    menuVisible(){
      return this.$store.getters['MENU_VISIBLE'];
    },
    inplayVisible(){
      return this.menuVisible?.find(e => e.name === '인플레이').visible
    },
    europeanVisible(){
      return this.menuVisible?.find(e => e.name === '스포츠-유럽형').visible
    },
    koreanVisible(){
      return this.menuVisible?.find(e => e.name === '스포츠-한국형').visible
    },
    korean2Visible(){
      return this.menuVisible?.find(e => e.name === '스포츠-한국형2').visible
    },
    liveVisible(){
      return this.menuVisible?.find(e => e.name === '라이브').visible
    },
    ...mapState({
      user: state => state.user.user,
      version: state => state.version.version,
    }),
  },
  methods:{
    logOut: function(){
      localStorage.removeItem('authToken');
      this.$store.dispatch("LOGOUT", null)
      this.$router.push({ path: '/main'})
    },
    handleScroll () {
      if (window.scrollY > 30) {
        this.navHeader = true;
      } else {
        this.navHeader = false;
      }
      // Any code to be executed when the window is scrolled
    },
    doLogin: function() {
      this.proceeding = true
      if(!this.signInForm.userId) return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.emptyId);
      if(!this.signInForm.userPw) return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.emptyPassword);
      this.signInForm.version = this.version
      this.$store.dispatch('LOGIN', this.signInForm)
          .then((response) => {
            if (response.status === 200) {
              // 로그인 성공시 처리
              this.$store.dispatch('SHOW_ERR_MSG', MSG.success.welcome);
              this.signInForm.userId = null
              this.signInForm.userPw = null
              this.proceeding = false
            }
          })
          .catch((error) => {
            // 로그인 실패시 처리
            this.$store.dispatch('SHOW_ERR_MSG', error.response.data.error);
            this.proceeding = false
            // alert(error.response.data.error);
          })
    },
    toggleModalTop: function() {
      this.toggle2 = !this.toggle2
      this.toggle = false;
    },
    toggleModalUser: function() {
      this.toggle = !this.toggle
      this.toggle2 = false;
    },
    subMouseOut(){
      // console.log('mouse out');
      this.subMenu = '';
    },
    subMouseIn(menu){
      // console.log(menu);
      this.subMenu = menu;
    },
    openCasino: function(game){
      if (!this.user) return alert(MSG.isNotLogin);
      const config = this.user.members_detail_setting;
      this.$store.dispatch('CHECK_CASINO_WALLET');
      if (game === 'evolution' && !this.menuVisible.find(e => e.name === '에볼루션').visible){
        return this.$store.dispatch('SHOW_ERR_MSG3', '에볼루션 점검중입니다.');
      }
      if (game === 'asia' && !this.menuVisible.find(e => e.name === '아시아').visible){
        return this.$store.dispatch('SHOW_ERR_MSG3', '아시아는 점검중입니다.');
      }
      if (game === 'evolution' && !config.베팅권한.에볼루션.active){
        this.$store.dispatch('SET_ERR_MSG', '에볼루션은 이용하실 수 없습니다.');
        this.$store.dispatch('SHOW_MODAL', 'error2');
        return;
      }
      if (game === 'asia' && !config.베팅권한.아시아게이밍.active){
        this.$store.dispatch('SET_ERR_MSG', '아시아게이밍은 이용하실 수 없습니다.');
        this.$store.dispatch('SHOW_MODAL', 'error2');
        return;
      }
      const data = {
        payload: {
          type: game === 'evolution' ? 45 : 44,
        }
      }
      this.$store.dispatch('GET_LOBBY', data)
          .then(data => {
            const result = data.data;
            if (result.payload) {
              window.open(result.payload.data.lobbyUrl, 'Casino', '_blank')
            }
          })
          .catch(err => {
            alert(err.response.data.error);
          })
    },
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>
<style>
.main_navigation {
    width: 259px;
    margin: 10px auto 0;
}
.main_navigation li {
    font-family: EsaManru !important;
    margin: 0px;
    position: relative;
    list-style: none;
    width: 100%;
    line-height: 46px;
    margin: 0 0 5px 0;
    background: url("../assets/images/bg_game.png") center top no-repeat;
    background-size: 100% 100%;
    position: relative;
}
.main_navigation li:last-child {
    border-bottom: none;
}
.main_navigation li a {
    display: block !important;
    font-size: 16px;
    font-weight: 300;
    color: #dcdcdc;
    margin-top: 0px;
    height: 46px;
    line-height: 46px;
    text-align: left;
    padding: 0 25px;
}
.main_navigation li a i {
    vertical-align: middle;
    font-size: 22px;
    transition: 0.3s;
    position: absolute;
    left: 3px;
    top: 2px;
    color: #00ffff;
    display: none
}
.main_navigation li a img {
    vertical-align: middle;
    margin-right: 10px;
}
.main_navigation li a img {
    transition: all 0.3s;
}
.main_navigation li a:hover img {
    transform: rotate(-42deg);
    filter: brightness(1.2);
}
.main_navigation li a img {
    -webkit-animation: left_icon 1.9s ease-in-out infinite both;
    animation: left_icon 1.9s ease-in-out infinite both;
}
</style>

