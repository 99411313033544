// import axios from 'axios';
import AuthenticationService from "../../services/authentication.service";
// const API_URL = process.env.VUE_APP_API_URL;
const getDefaultState = () => {
  return {
    originList: [],
    waitList: [],
  }
}

const state = getDefaultState()

const getters = {}

const actions = {
  CHECK_CASINO_WALLET(_, params) {
    return AuthenticationService.post(`/casino/v2/check/wallet`, params).then(
      (response) => {
        return response;
      }
    );
  },
  GET_LOBBY(_, params) {
    return AuthenticationService.post(`/casino/v2/lobby`, params).then(
      (response) => {
        return response;
      }
    );
  },
  GET_LIVE() {
    return AuthenticationService.get(`/casino/game/list`).then(
      (response) => {
        return response;
      }
    );
  },
  GET_SLOT(_, params) {
    return AuthenticationService.post(`/slot/game/list`, params).then(
      (response) => {
        return response;
      }
    );
  },
  GET_GAME(_, params) {
    return AuthenticationService.post(`/casino/v2/connect/link`, params).then(
      (response) => {
        return response;
      }
    );
  },
};

const mutations = {
  SET_INPLAY_LIST(state, payload) {
    state.originList = payload
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
