<template>
  <div class="modal" style="display: block;" v-if="isView">
    <div class="modal_content"><img src="../assets/images/login_item01.png?13" class="logingirl">
      <form action="" id="frm" class="lg_frm_arr">
        <i class="fi fi-tr-x modal-close" @click="$store.dispatch('INVISIBLE_MODAL', 'login')"></i>
        <img src="../assets/images/logo.png" class="frm-logo">
        <h4>로그인</h4>
        <input name="userid" type="text" id="userid" class="fadeIn second" size="15" maxlength="20"
              style="ime-mode:disabled" placeholder="아이디" v-model="signInForm.userId">
        <input name="passwd" type="password" id="passwd" class="fadeIn third" size="15" maxlength="20"
              placeholder="비밀번호" v-model="signInForm.userPw">
        <button id="login-btn" type="submit" class="fadeIn fourth" value="LOGIN" border="0" @click="doLogin">로그인</button>
        <router-link id="join-btn" class="fadeIn fourth" :to="{name:'회원가입'}">회원가입</router-link>
        <div class="kakap-sns"><i class="icofont icofont-social-telegram"></i>
            <strong> <a href="//t.me/up247cs" target="_blank">텔레그램문의</a></strong>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import MSG from "@/contants/msg";

export default {
  name: "Login",
  data: () => ({
    login_popup: false,
    chatScript: null,
    signInForm: {
      userId: '',
      userPw: '',
      sitename: 'casino',
      version: null,
    },
    proceeding: false
  }),
  computed: {
    isView: function(){
      return this.$store.state.modals.login
    },
  },
  methods: {
    movePage: function(uri){
      this.$router.push({ path: `/${uri}`})
    },
    doLogin: function() {
      this.proceeding=true
      if(!this.signInForm.userId) return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.emptyId);
      if(!this.signInForm.userPw) return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.emptyPassword);
      this.signInForm.version = 'new'
      this.$store.dispatch('LOGIN', this.signInForm)
          .then((response) => {
            if (response.status === 200) {
              // 로그인 성공시 처리
              this.$store.dispatch('SHOW_ERR_MSG', MSG.success.welcome);
              this.$router.push({ path: '/main'})
              this.proceeding=false
            }
          })
          .catch((error) => {
            // 로그인 실패시 처리
            this.$store.dispatch('SHOW_ERR_MSG', error.response.data.error);
            this.proceeding=false
            // alert(error.response.data.error);
          })
    },
  },
}
</script>

<style scoped>

.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.8);
    backdrop-filter: blur(5px);
    top: 0;
    left: 0;
    display: none;
	z-index: 99999
}
	.modal_content {
    width: 640px;
    height: 500px;
    background: #fff;
    border-radius: 10px;
    position: relative;
    top: 50%;
    left: 50%;
    margin-top: -250px;
    margin-left: -320px;
    text-align: center;
    box-sizing: border-box;
  
    line-height: 23px;
    cursor: pointer;
       background: linear-gradient(#1d1d1d 0%, #1d1d1d 100%);
    border-radius: 10px;
    box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.7);
}
.logingirl {
    position: absolute;
    left: 0;
    bottom: 0;
}
.lg_frm_arr {
    position: absolute;
    right: 20px;
    bottom: 0;
    left: 380px;
    top: 0;
}
.lg_frm_arr .frm-logo {
    display: block;
   
    margin: 0px auto 0 auto;
	width: 240px;
}
.lg_frm_arr h4 {
    font-size: 20px;
    color: #fff;
    border-bottom: solid 2px #444;
    padding: 15px 0;
}
#userid, #passwd {
    height: 50px;
    text-align: center;
    background: #222;
    border: solid 1px #444;
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 10px;
    outline: none;
    font-size: 14px;
}
#userid:focus, #passwd:focus {
    border: solid 1px #00ffff;
}
#login-btn, #join-btn {
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  display: block;
  margin-top: 10px;
  background: #00ffff;
  border-radius: 10px;
  font-weight: 500;
  color: #fff;  
  border: 1px solid #000;
  box-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3) inset;
  text-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
}
#login-btn {
	background: linear-gradient(#00ffff 0%, #007eff 100%);
}
	#join-btn {    background: linear-gradient(#7c7c7c 0%, #353535 100%);}
#login-btn:hover, #join-btn:hover {
    filter: brightness(1.3)
}
.modal-close {
    position: absolute;
    font-size: 30px;
    right: 10px;
    top: 10px;
    color: #fff;
    z-index: 9;
	transition: all 0.3s
}

	.modal-close:hover { color: #00ffff ; transform: rotate(360deg);}		
    
    .inloforma { padding: 20px; box-sizing: border-box;}   
    
        #header > .centerbox > .logo_img { width: 200px;}
        .wrapper > div { font-family: 'KBO-Dia-Gothic_bold' !important;}
	.kakap-sns {
    color: #fff;
    font-size: 14px;
    height: 50px;
    width: 100%;
    line-height: 50px;
    border: solid 1px #0bc8ff;
    background: rgba(0,0,0,0.8);
    margin: 10px 0;
    text-align: center;
	margin-top: 10px;
}
	
	
.kakap-sns i {
    color: #0bc8ff;
    font-size: 30px;
    vertical-align: middle;
}
	
.kakap-sns strong a {
    color: #0bc8ff;
    font-weight: bold;
}
</style>
