<template>
  <div class="repair">
    <div>
      <!-- <img src="@/assets/img/etc/logo.png"> -->
      <strong>서비스를 점검중입니다.</strong>
      <p>잠시만 기다려주세요</p>
<!--      <a href="/">메인 페이지로 가기</a>-->
    </div>
  </div>

</template>

<script>
export default {
  name: "index",
}
</script>

<style scoped>
</style>
