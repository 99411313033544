<template>
  <div class="sub_content_arr_bg">
    <div class="main-visual">
      <!-- <div class="slider slick-initialized slick-slider" role="toolbar">
          <button type="button" data-role="none" class="slick-prev slick-arrow" aria-label="Previous" role="button"
                  style="">Previous
          </button>


          <div aria-live="polite" class="slick-list draggable">
              <div class="slick-track" role="listbox" style="opacity: 1; width: 10980px; left: -5490px;">
                  <div class="slide slick-slide slick-cloned" data-slick-index="-1" aria-hidden="true" tabindex="-1"
                      style="width: 1830px;"><img src="../assets/images/slideshow01.png"></div>
                  <div class="slide slick-slide" data-slick-index="0" aria-hidden="true" tabindex="-1" role="option"
                      aria-describedby="slick-slide00" style="width: 1830px;"><img
                          src="../assets/images/slideshow04.png"></div>
                  <div class="slide slick-slide" data-slick-index="1" aria-hidden="true" tabindex="-1" role="option"
                      aria-describedby="slick-slide01" style="width: 1830px;"><img
                          src="../assets/images/slideshow03.png"></div>
                  <div class="slide slick-slide slick-current slick-active" data-slick-index="2" aria-hidden="false"
                      tabindex="-1" role="option" aria-describedby="slick-slide02" style="width: 1830px;"><img
                          src="../assets/images/slideshow02.png"></div>
                  <div class="slide slick-slide" data-slick-index="3" aria-hidden="true" tabindex="-1" role="option"
                      aria-describedby="slick-slide03" style="width: 1830px;"><img
                          src="../assets/images/slideshow01.png"></div>
                  <div class="slide slick-slide slick-cloned" data-slick-index="4" aria-hidden="true" tabindex="-1"
                      style="width: 1830px;"><img src="../assets/images/slideshow04.png"></div>
              </div>
          </div>


          <button type="button" data-role="none" class="slick-next slick-arrow" aria-label="Next" role="button" style="">
              Next
          </button>
          <ul class="slick-dots" style="" role="tablist">
              <li class="" aria-hidden="true" role="presentation" aria-selected="true" aria-controls="navigation00"
                  id="slick-slide00">
                  <button type="button" data-role="none" role="button" aria-required="false" tabindex="0">1</button>
              </li>
              <li aria-hidden="true" role="presentation" aria-selected="false" aria-controls="navigation01"
                  id="slick-slide01" class="">
                  <button type="button" data-role="none" role="button" aria-required="false" tabindex="0">2</button>
              </li>
              <li aria-hidden="false" role="presentation" aria-selected="false" aria-controls="navigation02"
                  id="slick-slide02" class="slick-active">
                  <button type="button" data-role="none" role="button" aria-required="false" tabindex="0">3</button>
              </li>
              <li aria-hidden="true" role="presentation" aria-selected="false" aria-controls="navigation03"
                  id="slick-slide03" class="">
                  <button type="button" data-role="none" role="button" aria-required="false" tabindex="0">4</button>
              </li>
          </ul>
      </div> -->
      <!-- <link rel="stylesheet"
            href="https://cdn.jsdelivr.net/gh/theeluwin/NotoSansKR-Hestia@master/stylesheets/NotoSansKR-Hestia.css"
            integrity="sha384-zGzb8nSrN9lm5PPcfztVC5DjjTs5sVStV85IH8x51fM9yIwmI+Uvh3RZDJlrpPWQ" crossorigin="anonymous">
      <link rel="stylesheet" href="https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css"> -->
      <div class="container_row ">
          <div class="toggle_inner">
              <button class="btn_toggle" :class="{'active': type === 'casino'}" data-tab="one" @click="type = 'casino'"> 카지노<span>CASINO</span>&nbsp; <img
                      src="../assets/images/main_game_icon01.png"></button>
              <button class="btn_toggle " :class="{'active': type === 'slot'}" data-tab="two" @click="type = 'slot'"> 슬롯게임<span>SLOT GAME</span>&nbsp; <img
                      src="../assets/images/main_game_icon02.png"></button>
              <button class="btn_toggle " :class="{'active': type === 'minigame'}" data-tab="three" @click="type = 'minigame'"> 미니게임<span>MINI GAME</span>&nbsp; <img
                      src="../assets/images/main_game_icon03.png"></button>
          </div>
          <div class="toggle_content">
              <!------------------------------- 카지노 --------------------------------->
              <div class="toggle_section casino_box " id="tab-one" :class="{'active': type === 'casino'}">
                  <div class="real_list casino-g">
                    <router-link :to="{name:'카지노-목록'}" class="game_item companys-title code1" data-thirdpartycode="1"
                                                    data-gamecode="evolution_top_games" data-mutype="9">
                      <div class="border"><img class="game_logo" src="../assets/images/casino/casino_1.png">
                          <div class="game_title">에볼루션</div>
                      </div>
                    </router-link> 
                    <a class="game_item companys-title code33" data-thirdpartycode="33" data-gamecode="101"
                          data-mutype="9">
                        <div class="border"><img class="game_logo" src="../assets/images/casino/casino_33.png">
                            <div class="game_title">프라그마틱</div>
                        </div>
                    </a> 
                  <a class="game_item companys-title code12" data-thirdpartycode="12" data-gamecode="casino"
                          data-mutype="9">
                      <div class="border"><img class="game_logo" src="../assets/images/casino/casino_12.png">
                          <div class="game_title">WM카지노</div>
                      </div>
                  </a> <a class="game_item companys-title code8" data-thirdpartycode="8" data-gamecode="casino"
                          data-mutype="9">
                      <div class="border"><img class="game_logo" src="../assets/images/casino/casino_8.png">
                          <div class="game_title">드림게임</div>
                      </div>
                  </a> <a class="game_item companys-title code46" data-thirdpartycode="46" data-gamecode="BTV-baccarat"
                          data-mutype="9">
                      <div class="border"><img class="game_logo" src="../assets/images/casino/casino_46.png">
                          <div class="game_title">벳게임즈</div>
                      </div>
                  </a> <a class="game_item companys-title code47" data-thirdpartycode="47" data-gamecode="SWL-baccarat"
                          data-mutype="9">
                      <div class="border"><img class="game_logo" src="../assets/images/casino/casino_47.png">
                          <div class="game_title">스카이윈드</div>
                      </div>
                  </a> <a class="game_item companys-title code51" data-thirdpartycode="51" data-gamecode="taishan"
                          data-mutype="9">
                      <div class="border"><img class="game_logo" src="../assets/images/casino/casino_51.png">
                          <div class="game_title">타이산</div>
                      </div>
                  </a> <a class="game_item companys-title code54" data-thirdpartycode="54"
                          data-gamecode="SMG_titaniumLiveGames_MP_Baccarat" data-mutype="9">
                      <div class="border"><img class="game_logo" src="../assets/images/casino/casino_54.png">
                          <div class="game_title">마이크로게이밍</div>
                      </div>
                  </a> <a class="game_item companys-title code28" data-thirdpartycode="28" data-gamecode="speed"
                          data-mutype="9">
                      <div class="border"><img class="game_logo" src="../assets/images/casino/casino_28.png">
                          <div class="game_title">보타(TG/PD)</div>
                      </div>
                  </a> <a class="game_item companys-title code53" data-thirdpartycode="53" data-gamecode="dowin"
                          data-mutype="9">
                      <div class="border"><img class="game_logo" src="../assets/images/casino/casino_53.png">
                          <div class="game_title">두윈카지노</div>
                      </div>
                  </a> <a class="game_item companys-title code60" data-thirdpartycode="60" data-gamecode="bal"
                          data-mutype="9">
                      <div class="border"><img class="game_logo" src="../assets/images/casino/casino_60.png">
                          <div class="game_title">플레이테크카지노</div>
                      </div>
                  </a> <a class="game_item companys-title code13" data-thirdpartycode="13" data-gamecode="VGL-lobby"
                          data-mutype="9">
                      <div class="border"><img class="game_logo" src="../assets/images/casino/casino_13.png">
                          <div class="game_title">비보게이밍</div>
                      </div>
                  </a> <a class="game_item companys-title code63" data-thirdpartycode="63" data-gamecode="GINKGO01"
                          data-mutype="9">
                      <div class="border"><img class="game_logo" src="../assets/images/casino/casino_63.png">
                          <div class="game_title">모티베이션</div>
                      </div>
                  </a> <a class="game_item companys-title code67" data-thirdpartycode="67"
                          data-gamecode="MGL_GRAND_LobbyAll" data-mutype="9">
                      <div class="border"><img class="game_logo" src="../assets/images/casino/casino_67.png">
                          <div class="game_title">MG Live Grand</div>
                      </div>
                  </a> <a class="game_item companys-title code66" data-thirdpartycode="66" data-gamecode="lobby"
                          data-mutype="9">
                      <div class="border"><img class="game_logo" src="../assets/images/casino/casino_66.png">
                          <div class="game_title">아스타</div>
                      </div>
                  </a></div>
                  <a class="game_item companys-title code<!--thirdpartycode-->"
                    data-thirdpartycode="<!--thirdpartycode-->" data-gamecode="<!--gamecode-->"
                    data-mutype="<!--mu_type-->">
                  </a>
            </div>

              <!------------------------------- 카지노 --------------------------------->

              <!------------------------------- 슬롯 --------------------------------->
              <div class="toggle_section slot_box " id="tab-two" :class="{'active': type === 'slot'}">
                  <div class="real_list slot-g"><a class="game_item companys-title companys-title2 code7"
                                                  data-thirdpartycode="7" data-gamecode="" data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_7.png">
                          <div class="game_title">프라그마틱슬롯</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code52" data-thirdpartycode="52"
                          data-gamecode="" data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_52.png">
                          <div class="game_title">프라그마틱슬롯2</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code9" data-thirdpartycode="9" data-gamecode=""
                          data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_9.png">
                          <div class="game_title">부운고</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code37" data-thirdpartycode="37"
                          data-gamecode="" data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_37.png">
                          <div class="game_title">PG소프트</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code17" data-thirdpartycode="17"
                          data-gamecode="" data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_17.png">
                          <div class="game_title">와즈단</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code25" data-thirdpartycode="25"
                          data-gamecode="" data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_25.png">
                          <div class="game_title">하바네로</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code34" data-thirdpartycode="34"
                          data-gamecode="" data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_34.png">
                          <div class="game_title">트리플프로필</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code40" data-thirdpartycode="40"
                          data-gamecode="" data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_40.png">
                          <div class="game_title">블루프린트</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code41" data-thirdpartycode="41"
                          data-gamecode="" data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_41.png">
                          <div class="game_title">썬더킥</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code42" data-thirdpartycode="42"
                          data-gamecode="" data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_42.png">
                          <div class="game_title">노리밋시티</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code43" data-thirdpartycode="43"
                          data-gamecode="" data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_43.png">
                          <div class="game_title">모빌롯츠</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code44" data-thirdpartycode="44"
                          data-gamecode="" data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_44.png">
                          <div class="game_title">플레이펄스</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code45" data-thirdpartycode="45"
                          data-gamecode="" data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_45.png">
                          <div class="game_title">드라군소프트</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code4" data-thirdpartycode="4" data-gamecode=""
                          data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_4.png">
                          <div class="game_title">시큐9</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code3" data-thirdpartycode="3" data-gamecode=""
                          data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_3.png">
                          <div class="game_title">에보플레이</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code24" data-thirdpartycode="24"
                          data-gamecode="" data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_24.png">
                          <div class="game_title">플레이스타</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code50" data-thirdpartycode="50"
                          data-gamecode="" data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_50.png">
                          <div class="game_title">Elk</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code49" data-thirdpartycode="49"
                          data-gamecode="" data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_49.png">
                          <div class="game_title">1x2게이밍</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code26" data-thirdpartycode="26"
                          data-gamecode="" data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_26.png">
                          <div class="game_title">플레이엔고</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code23" data-thirdpartycode="23"
                          data-gamecode="" data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_23.png">
                          <div class="game_title">플레이슨</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code55" data-thirdpartycode="55"
                          data-gamecode="" data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_55.png">
                          <div class="game_title">마이크로게이밍슬롯</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code22" data-thirdpartycode="22"
                          data-gamecode="" data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_22.png">
                          <div class="game_title">레드타이거</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code20" data-thirdpartycode="20"
                          data-gamecode="" data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_20.png">
                          <div class="game_title">넷엔트</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code56" data-thirdpartycode="56"
                          data-gamecode="" data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_56.png">
                          <div class="game_title">빅타임게이밍</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code6" data-thirdpartycode="6" data-gamecode=""
                          data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_6.png">
                          <div class="game_title">YGG</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code58" data-thirdpartycode="58"
                          data-gamecode="" data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_58.png">
                          <div class="game_title">아바타UX</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code59" data-thirdpartycode="59"
                          data-gamecode="" data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_59.png">
                          <div class="game_title">핵쏘</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code61" data-thirdpartycode="61"
                          data-gamecode="" data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_61.png">
                          <div class="game_title">플레이테크슬롯</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code64" data-thirdpartycode="64"
                          data-gamecode="" data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_64.png">
                          <div class="game_title">스카이윈드슬롯</div>
                      </div>
                  </a> <a class="game_item companys-title companys-title2 code65" data-thirdpartycode="65"
                          data-gamecode="" data-mutype="10">
                      <div class="border"><img class="game_logo" src="../assets/images/slot/slot_65.png">
                          <div class="game_title">온리플레이</div>
                      </div>
                  </a></div>
                  <a class="game_item companys-title companys-title2 code<!--thirdpartycode-->"
                    data-thirdpartycode="<!--thirdpartycode-->" data-gamecode="<!--gamecode-->"
                    data-mutype="<!--mu_type-->">
                  </a></div>
              <div class="toggle_section slot_box" id="tab-three" :class="{'active': type === 'minigame'}">
                  <div class="real_list">
                      <a href="game_list.asp?bt_type=13">
                          <div class="border"><img class="gmae_img" src="../assets/images/minigame/mini13.png">
                              <div class="game_title">가상축구</div>
                          </div>
                      </a><a href="game_list.asp?bt_type=16">
                      <div class="border"><img class="gmae_img" src="../assets/images/minigame/mini16.png">
                          <div class="game_title">별다리1분</div>
                      </div>
                  </a><a href="game_list.asp?bt_type=71">
                      <div class="border"><img class="gmae_img" src="../assets/images/minigame/mini71.png">
                          <div class="game_title">로터스홀짝</div>
                      </div>
                  </a><a href="game_list.asp?bt_type=83">
                      <div class="border"><img class="gmae_img" src="../assets/images/minigame/mini83.png">
                          <div class="game_title">로터스바카라</div>
                      </div>
                  </a><a href="game_list.asp?bt_type=116">
                      <div class="border"><img class="gmae_img" src="../assets/images/minigame/mini116.png">
                          <div class="game_title">경마</div>
                      </div>
                  </a><a href="game_list.asp?bt_type=591">
                      <div class="border"><img class="gmae_img" src="../assets/images/minigame/mini591.png">
                          <div class="game_title">슈퍼사다리1분</div>
                      </div>
                  </a><a href="game_list.asp?bt_type=594">
                      <div class="border"><img class="gmae_img" src="../assets/images/minigame/mini594.png">
                          <div class="game_title">슈퍼로하이1분</div>
                      </div>
                  </a><a href="game_list.asp?bt_type=597">
                      <div class="border"><img class="gmae_img" src="../assets/images/minigame/mini597.png">
                          <div class="game_title">슈퍼섯다</div>
                      </div>
                  </a><a href="game_list.asp?bt_type=884">
                      <div class="border"><img class="gmae_img" src="../assets/images/minigame/mini884.png">
                          <div class="game_title">구글바카라1</div>
                      </div>
                  </a><a href="game_list.asp?bt_type=881">
                      <div class="border"><img class="gmae_img" src="../assets/images/minigame/mini881.png">
                          <div class="game_title">구글파워볼1</div>
                      </div>
                  </a><a href="game_list.asp?bt_type=882">
                      <div class="border"><img class="gmae_img" src="../assets/images/minigame/mini882.png">
                          <div class="game_title">구글파워볼2</div>
                      </div>
                  </a><a href="game_list.asp?bt_type=883">
                      <div class="border"><img class="gmae_img" src="../assets/images/minigame/mini883.png">
                          <div class="game_title">구글파워볼3</div>
                      </div>
                  </a><a href="game_list.asp?bt_type=878">
                      <div class="border"><img class="gmae_img" src="../assets/images/minigame/mini878.png">
                          <div class="game_title">구글사다리1</div>
                      </div>
                  </a><a href="game_list.asp?bt_type=879">
                      <div class="border"><img class="gmae_img" src="../assets/images/minigame/mini879.png">
                          <div class="game_title">구글사다리2</div>
                      </div>
                  </a><a href="game_list.asp?bt_type=880">
                      <div class="border"><img class="gmae_img" src="../assets/images/minigame/mini880.png">
                          <div class="game_title">구글사다리3</div>
                      </div>
                  </a>
                  </div>
              </div>

              <!------------------------------- 슬롯 --------------------------------->
          </div>
      </div>
      <!-- <script type="text/javascript">
          //tab
          $('.btn_toggle').click(function () {
              var tab = $(this).data('tab')
              $(this).addClass('active').siblings('.btn_toggle').removeClass('active');
              $('#tab-' + tab + '').addClass('active').siblings('.toggle_section').removeClass('active');
          });
      </script> -->
      <div id="slot-game" style="display: none;">
          <div class="slot-game-layer" style="display: none;">
              <button type="buttn" id="close-slot-game"><i class="icofont icofont-close"></i></button>
              <div>
                  <input type="text" name="slot_search" id="slot_search" placeholder="search"
                        style="width: 70%; box-sizing: border-box; margin: 5px auto; display: block; text-align: center;">
              </div>
              <ul id="slot-game-list">
              </ul>
          </div>
      </div>
      <div class="ly002" style="display:none;">
          <div class="point_change" style="display:none;"><span id="ly003-close" style="cursor: pointer;"><i
                  class="fa fa-times close-point-change"></i></span>
              <div class="my-wallet">
                  <div class="game-money">
                      <p>사이트 포인트(머니)</p>
                      <div><span class="sports_point">0</span></div>
                  </div>
                  <div class="sports-money">
                      <p>카지노 포인트</p>
                      <div><span class="casino_point">0</span></div>
                  </div>
                  <span class="arw-center"></span></div>
              <div class="wallet-move">
                  <select id="w_from" name="w_from">
                      <option value="04">카지노 포인트</option>
                      <option value="01" selected="">사이트 포인트(머니)</option>
                  </select>
                  <span>&nbsp;에서</span>
                  <input type="text" id="amount" name="amount" placeholder="전환하실 포인트를 입력해 주세요." class="money">
                  <select id="w_to" name="w_to">
                      <option value="01">사이트 포인트(머니)</option>
                      <option value="04" selected="">카지노 포인트</option>
                  </select>
                  <span id="btnMoneyAct" class="ui-button_red" style="cursor: pointer;">전환하기</span></div>
          </div>
      </div>
      <!-- <script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.5.9/slick.min.js"></script>
      <script type="text/javascript" language="javascript" src="/framework/js/lib.comm.js"></script>
      <script type="text/javascript" language="javascript" src="/framework/js/lib.stringbuilder.js"></script>
      <script type="text/javascript" language="javascript" src="/framework/js/lib.string.js"></script>
      <script language="JavaScript" type="text/javascript" src="/html/common/js/lobby_swix.js?12023-104-14"></script>
      <script type="text/javascript">
          var lists = [];
          var runningFlag = false;
          $(document).ready(function () {
              getLobyList(9, "casino-g");
              getLobyList(10, "slot-g");


              setTimeout(function () {
                  display_add();
              }, 1500);


          });
      </script> -->

      <div class="first_module mainBoard">
          <div class="event_list">
              <h6>공지사항</h6>
              <div>
                  <ul class="main-slide-box main-cashin-slide">
                      <li><a href="/content.asp?mode=content&amp;code_group=65&amp;code_no=65&amp;code=7&amp;seq=20">
                          <font size="2" color="White" face="고딕"> <font color="#61FFFF"> ★ </font> 롤링규정안내 <font
                                  color="#61FFFF"> ★ </font></font> </a></li>
                      <li><a href="/content.asp?mode=content&amp;code_group=65&amp;code_no=65&amp;code=7&amp;seq=19">
                          <font size="2" color="White" face="고딕"> <font color="#61FFFF"> ★ </font> 최대베팅금액및당첨금액안내 <font
                                  color="#61FFFF"> ★ </font></font> </a></li>
                      <li><a href="/content.asp?mode=content&amp;code_group=65&amp;code_no=65&amp;code=7&amp;seq=18">
                          <font size="2" color="White" face="고딕"> <font color="#61FFFF"> ★ </font> 입금 및 출금 안내 <font
                                  color="#61FFFF"> ★ </font></font> </a></li>
                      <li><a href="/content.asp?mode=content&amp;code_group=65&amp;code_no=65&amp;code=7&amp;seq=17">
                          <font size="2" color="White" face="고딕"> <font color="#61FFFF"> ★ </font> 유의사항 안내 <font
                                  color="#61FFFF"> ★ </font></font> </a></li>
                      <li><a href="/content.asp?mode=content&amp;code_group=65&amp;code_no=65&amp;code=7&amp;seq=16">
                          <font size="2" color="White" face="고딕"> <font color="#61FFFF"> ★ </font> 양방베팅 근절 안내 <font
                                  color="#61FFFF"> ★ </font></font> </a></li>
                  </ul>
              </div>
          </div>
          <div class="event_list noDot">
              <h6>이벤트</h6>
              <div>
                  <ul class="main-slide-box main-cashin-slide">
                      <li><a href="/event.asp?seq=88150"> <font size="2" color="White" face="고딕"> <font color="#61FFFF">
                          ★ </font> 복귀자 무한입플 이벤트 <font color="#61FFFF"> ★ </font></font> </a></li>
                      <li><a href="/event.asp?seq=88148"> <font size="2" color="White" face="고딕"> <font color="#61FFFF">
                          ★ </font> 복귀자 페이백 상향 이벤트 <font color="#61FFFF"> ★ </font></font> </a></li>
                      <li><a href="/event.asp?seq=80992"> <font size="2" color="White" face="고딕"> <font color="#61FFFF">
                          ★ </font> 일일 누적 충전 횟수 이벤트 <font color="#61FFFF"> ★ </font></font> </a></li>
                      <li><a href="/event.asp?seq=80025"> <font size="2" color="White" face="고딕"> <font color="#61FFFF">
                          ★ </font> 입금플러스 무한도전 이벤트 <font color="#61FFFF"> ★ </font></font> </a></li>
                      <li><a href="/event.asp?seq=78715"> <font size="2" color="White" face="고딕">
                          <font color="#61FFFF"> ★ </font> 주말 기프티콘 이벤트 <font color="#61FFFF"> ★ </font></font> </a></li>
                  </ul>
              </div>
          </div>
          <div class="event_list noDot">
              <h6>주간 출금 TOP 10</h6>
              <div>
                  <ul class="main-slide-box main-cashin-slide withdrawWeekTopList2" id="vertical-ticker2"
                      style="height: 220px; overflow: hidden;">

                      <li style="margin-top: 0px;"><span class="list-name">se*** </span><span class="list-cash">105,190,000</span>
                          <!-- span class="table_cell">2024.11.11</span --></li>
                      <li style="margin-top: 0px;"><span class="list-name">gn*** </span><span class="list-cash">104,250,000</span>
                          <!-- span class="table_cell">2024.11.11</span --></li>
                      <li style="margin-top: 0px;"><span class="list-name">en*** </span><span class="list-cash">100,000,000</span>
                          <!-- span class="table_cell">2024.11.11</span --></li>
                      <li style="margin-top: 0px;"><span class="list-name">wl*** </span><span class="list-cash">170,000,000</span>
                          <!-- span class="table_cell">2024.11.11</span --></li>
                      <li style="margin-top: 0px;"><span class="list-name">en*** </span><span class="list-cash">165,890,000</span>
                          <!-- span class="table_cell">2024.11.11</span --></li>
                      <li style="margin-top: 0px;"><span class="list-name">vk*** </span><span class="list-cash">150,000,000</span>
                          <!-- span class="table_cell">2024.11.11</span --></li>
                      <li style="margin-top: 0px;"><span class="list-name">cc*** </span><span class="list-cash">127,000,000</span>
                          <!-- span class="table_cell">2024.11.11</span --></li>
                      <li style="margin-top: 0px;"><span class="list-name">wl*** </span><span class="list-cash">126,880,000</span>
                          <!-- span class="table_cell">2024.11.11</span --></li>
                      <li style="margin-top: 0px;"><span class="list-name">all*** </span><span class="list-cash">107,500,000</span>
                          <!-- span class="table_cell">2024.11.11</span --></li>
                      <li style="margin-top: 0px;"><span class="list-name">alr*** </span><span class="list-cash">106,110,000</span>
                          <!-- span class="table_cell">2024.11.11</span --></li>
                  </ul>
              </div>
          </div>
      </div>
    </div>
  </div>

</template>

<script>
import "swiper/css/swiper.css";
// import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import EventPopup from "@/components/EventPopup";
// import MEventPopup from "@/components/MEventPopup";
// import WithdrawalList from "@/components/WithdrawalList";
// import PreAutoScroll from "@/components/PreAutoScroll";
//import InpAutoScroll from "@/components/InpAutoScroll";
//import Popular from "@/components/Popular";
import {mapState} from "vuex";
// import Typewriter from "typewriter-vue";

export default {
  name: "Index",
  components: {
    // Swiper,
    // SwiperSlide,
    // EventPopup,
    // MEventPopup,
    // WithdrawalList,
    //InpAutoScroll,
    // PreAutoScroll,
    //Popular,
    // Typewriter
  },
  data: () => {
    return {
      src: "https://static-sdsoft.s3.ap-northeast-1.amazonaws.com/video.mp4",
      swiperOptions: {
        loop: true,
        autoplay: {
          delay: 1500,
          disableOnInteraction: true,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "bullets",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      notice: [],
      event: [],
      popular: [],
      replace: [
        { from: "SOCCER", to: "BASEBALL" },
        { from: "BASEBALL", to: "RUGBY" },
        { from: "RUGBY", to: "BRING IT ON." },
      ],
      type: 'casino',
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.$forceUpdate();

  },
  methods: {
  },
  computed: {
    menuVisible() {
      return this.$store.getters["MENU_VISIBLE"];
    },
    inplayVisible() {
      return this.menuVisible?.find((e) => e.name === "인플레이").visible;
    },
    europeanVisible() {
      return this.menuVisible?.find((e) => e.name === "스포츠-유럽형").visible;
    },
    koreanVisible() {
      return this.menuVisible?.find((e) => e.name === "스포츠-한국형").visible;
    },
    korean2Visible() {
      return this.menuVisible?.find((e) => e.name === "스포츠-한국형2").visible;
    },
    liveVisible() {
      return this.menuVisible?.find((e) => e.name === "라이브").visible;
    },
    ...mapState({
      user: state => state.user.user,
    }),
  },
};
</script>

<style type="text/css">
#close-slot-game {
    position: fixed;
    top: 40px;
    font-size: 40px;
    float: right;
    background: none;
}

#close-slot-game i {
    width: 100%;
    display: block;
    text-align: right;
    color: #fff;
    font-size: 35px;
}

#slot-game {
    position: fixed;
    left: 250px;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9998
}

#slot-game .slot-game-layer {
    width: 1200px;
    position: absolute;
    left: 50%;
    top: 80px;
    bottom: 80px;
    overflow: hidden !important;
    overflow-y: auto !important;
    margin-left: -600px;
    z-index: 9990;
    background: #111;
    border: solid 10px #444;
    box-sizing: border-box;
}

.slot-game-layer::-webkit-scrollbar {
    width: 10px;
}

.slot-game-layer::-webkit-scrollbar-thumb {
    background-color: #ffcc00;
    border-radius: 10px;
}

.slot-game-layer::-webkit-scrollbar-track {
    background-color: #444;
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
}

#slot-game-list {
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
}

#slot-game-list:after {
    content: "";
    display: block;
    clear: both;
}

#slot-game-list li {
    width: 19%;
    margin: 0.5%;
    float: left;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    list-style: none;
}

#slot-game-list li img {
    width: 100%;
    height: 135px;
}

#slot-game-list li span {
    background: #252525;
    display: block;
    height: 25px;
    line-height: 25px;
    color: #fff;
}

.item_list .game_item {
    cursor: pointer
}

.game_thumb {
    background-size: cover;
    padding-top: 100%;
}

.toggle_inner {
    display: flex;
    margin-bottom: 20px;
    filter: drop-shadow(0 6px 12px rgba(0, 0, 0, .9))
}

.toggle_inner > button {
    display: flex;
    align-items: center;
    width: 98%;
    margin: 0 1%;
    height: 80px;
    border-radius: 10px;
    background: #212121;
    padding: 0 20px 0 30px;
    font-family: 'EsaManru';
    font-weight: 500;
    font-size: 36px;
    color: #676767;
    text-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    cursor: pointer
}

.toggle_inner > button i {
    vertical-align: middle;
    font-size: 18px;
}

.toggle_inner > button.active, .toggle_inner > button:hover {
    background: linear-gradient(to right, #00ffff 0%, #5000bc 100%);
    color: #ffffff;
}

.toggle_inner > button img {
    display: inline-block;
    margin: 0 0 0 auto;
    filter: grayscale(100%) brightness(0.4);
    transition: all 0.5s;
}

.toggle_inner > button.active img, .toggle_inner > button:hover img {
    filter: grayscale(0%) brightness(1);
}

.toggle_inner > .button_casino {
    padding: 0 0 0 60px;
}

.toggle_inner > .button_slot {
    padding: 0 60px 0 0;
}

.toggle_inner > .button_casino:after {
    content: "";
    position: absolute;
    left: 0;
    top: -25px;
    width: 150px;
    height: 150px;
    /* background: url(../img/banner_casino.4f3c669d.svg) no-repeat 50%; */
    background-size: 150px 150px;
    opacity: .2
}

.toggle_inner > .button_casino.active:after, .toggle_inner > .button_casino:hover:after {
    -webkit-animation: flip_banner 3s ease infinite;
    animation: flip_banner 3s ease infinite;
    -webkit-backface-visibility: visible;
    backface-visibility: visible
}

.toggle_inner > .button_slot:after {
    content: "";
    position: absolute;
    right: 0;
    top: -25px;
    width: 150px;
    height: 150px;
    /* background: url(../img/banner_slot.7e82a499.svg) no-repeat 50%; */
    background-size: 150px 150px;
    opacity: .15
}

.toggle_inner > .button_slot.active:after, .toggle_inner > .button_slot:hover:after {
    -webkit-animation: flip_banner 3s ease infinite;
    animation: flip_banner 3s ease infinite;
    -webkit-backface-visibility: visible;
    backface-visibility: visible
}

@-webkit-keyframes flip_banner {
    0% {
        transform: perspective(400px) rotateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    40% {
        transform: perspective(400px) translateZ(150px) rotateY(170deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    50% {
        transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
    80% {
        transform: perspective(400px) rotateY(1turn) scale(.95);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
    to {
        transform: perspective(400px) scale(1);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
}

@keyframes flip_banner {
    0% {
        transform: perspective(400px) rotateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    40% {
        transform: perspective(400px) translateZ(150px) rotateY(170deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    50% {
        transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
    80% {
        transform: perspective(400px) rotateY(1turn) scale(.95);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
    to {
        transform: perspective(400px) scale(1);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
}

.toggle_content {
    margin: 0px 0 0 0
}

.toggle_content .toggle_section {
    position: relative;
    display: none;
    margin: 0 0 var(--gap) 0;
}

.toggle_content .toggle_section.active {
    display: block
}

.item_list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    margin: calc(0px - var(--gap)) 0 0 calc(0px - var(--gap))
}

.item_list > a {
    position: relative;
    display: block;
    transition: var(--trans)
}

.item_list .game_item {
    width: calc(20% - var(--gap));
    padding: 5px;
    margin: var(--gap) 0 0 var(--gap);
    background: linear-gradient(0deg, #24262b 0, #3a3c43 99.5%, #686b77);
    box-shadow: 0 10px 15px rgba(0, 0, 0, .3)
}

.item_list .game_item:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: var(--trans);
    opacity: 0;
    z-index: -1
}

.item_list .game_item .game_box {
    height: 100%;
    border: 1px solid #111;
    background: #191a1d
}

.item_list .game_item .game_box .game_thumb {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 0;
    padding-top: 102%;
    z-index: 1
}

.item_list .game_item .game_box .game_thumb:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 6px 12px 0 rgba(0, 0, 0, .75)
}

.item_list .game_item .game_box .game_thumb .thumb_cover, .item_list .game_item .game_box .game_thumb > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: var(--trans)
}

.item_list .game_item .game_box .game_thumb .thumb_cover {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: rgba(0, 0, 0, .7);
    opacity: 0;
    z-index: 5
}

.item_list .game_item .game_box .game_thumb .icon_play {
    width: 58%;
    height: 58%;
    margin: 0 auto 5px auto;
    filter: drop-shadow(0 6px 6px rgba(0, 0, 0, .9));
    z-index: 1
}

.item_list .game_item .game_box .game_info {
    height: 62px;
    line-height: 1.4;
    padding: 10px 0;
    text-align: center;
    transition: var(--trans)
}

.item_list .game_item .game_box .game_info h3 {
    color: #eee;
    font-size: 18px;
    font-weight: 500
}

.item_list .game_item .game_box .game_info h4 {
    color: #999;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0
}

.item_list .game_item .game_box .game_info .btn_start {
    display: block;
    width: calc(100% - 20px);
    margin: 10px 10px 0 10px;
    padding: 8px 0;
    background: #d30cd4;
    color: #fff;
    font-size: 16px;
    opacity: 0;
    transform: scale(0);
    transition: var(--trans)
}

.item_list .game_item:hover {
    margin-top: calc(0px - var(--gap));
    margin-bottom: calc(0px - var(--gap) * 2);
    box-shadow: 0 10px 15px rgba(0, 0, 0, .9);
    z-index: 3
}

.item_list .game_item:hover:after {
    background: linear-gradient(to bottom, #faeba6 1%, #957141 85%, #725130 100%);
    opacity: 1
}

.item_list .game_item:hover .game_thumb > img {
    transform: scale(1.2)
}

.item_list .game_item:hover .game_thumb .thumb_cover {
    opacity: 1
}

.item_list .game_item:hover .game_thumb .icon_play {
    -webkit-animation: icon_play .4s ease-in-out infinite alternate;
    animation: icon_play .4s ease-in-out infinite alternate;
    z-index: -1
}

.item_list .game_item:hover .game_info {
    height: 114px
}

.item_list .game_item:hover .game_info h3 {
    color: #edd415
}

.item_list .game_item:hover .game_info h4 {
    color: #ccc
}

.item_list .game_item:hover .game_info .btn_start {
    opacity: 1;
    transform: scale(1)
}

.item_list .game_item.off:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    z-index: 2
}

@-webkit-keyframes icon_play {
    0% {
        transform: scale(1)
    }
    to {
        transform: scale(1.04)
    }
}

@keyframes icon_play {
    0% {
        transform: scale(1)
    }
    to {
        transform: scale(1.04)
    }
}

.real_list {
    margin: 0 auto;
    width: 100%;
    display: grid;
    grid-template: repeat(1, 1fr) / repeat(6, 1fr);
    grid-gap: 30px;
    padding: 11px;
    padding: 11px;
}

.real_list a {
    width: 100%;
    flex-shrink: 0;
    position: relative;
    margin-top: 1px;
    margin-bottom: 1px;
    cursor: pointer;
    transition: all 300ms;
    z-index: 10;
    display: block;
}

.real_list .game_title {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 40%;
    transition: all 500ms;
    opacity: 0;
    display: none
}

.real_list img {
    width: 100%;
    overflow: hidden;
    display: block;
    transition: all 500ms;
}

.real_list a:hover {
    -webkit-animation: game_list 0.5s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
    animation: game_list 0.5s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
}

@keyframes game_list {
    from {
        -webkit-transform: translateX(0px) rotateY(0deg) skew(0deg, 0deg);
        transform: translateX(0px) rotateY(0deg) skew(0deg, 0deg);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        filter: brightness(1);
    }
    5% {
        -webkit-transform: translateX(0px) rotateY(2deg) skew(3deg, 0deg);
        transform: translateX(0px) rotateY(2deg) skew(3deg, 0deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        filter: brightness(1.2);
    }
}

.container_row {
    padding: 30px 50px;
}

.main-visual { width: 100%}
    .slide img {
    width: 100%;
}
.slick-slider {
    margin-bottom: 0px !important;
}
.slick-dots {
    bottom: 10px !important;
}
.slick-dots li button:before {
    color: #00ffff !important;
    border: solid 2px #fff;
    border-radius: 100px;
}
    .main_visual_latest > .visualbox { height: 300px !important;}  
    .main_visual_latest > .visualbox > .visual_entity {bottom: -150px !important;  width: 700px !important; }
    .main_visual_latest > .visualbox:last-child .visual_entity{bottom: -100px !important}
    .main_visual_latest > .visualbox > .centerbox { width: 90% !important;}
	
	
	
.mainBoard {
	
	margin-right: 0;
	padding:0 50px 50px 50px;
}
.mainBoard:after {content:""; display:block; clear:both;} 		
.event_list {
	float: left;
    width: 31.33%;
	margin: 1%;
    height: 300px;
   
   
      float: left;
 
   
   
    padding: 20px 20px 20px 20px;
    border-radius: 10px;
    background: #222222;
    border-top: 2px solid #00ffff;
}
	.event_list h6 { font-size: 20px; font-weight: 500; margin-bottom: 10px;}	



.event_list .read_more {
	float: right;
	color: #b8b8b8;
	font-size: 12px;
	display: inline-block;
	text-decoration: none;
	transition: all 0.2s ease-in-out;
	font-style: normal;
}
.event_list .read_more a {
	color: #b8b8b8;
	font-size: 11px;
}
.event_list .read_more i {
	color: #fbd000;
	font-style: normal;
}
.event_list i {
	vertical-align: middle;
	font-size: 27px;
	position: relative;
	top: 1px;
	color: #b8b8b8;
}
	.event_list li { list-style: none;  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
   
  
    font-size: 13px;
    line-height: 42px;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;}	
	.slick-next { right: 10px !important;   background-size: contain; z-index: 2}
	
	.slick-prev {left: 10px !important; background-size: contain; z-index: 2}
	.slick-next:before, .slick-prev:before { font-size: 40px; color: #00ffff;}
	.slick-next, .slick-prev { 

    width: 40px;
		height: 40px;}

	.withdrawWeekTopList2 .list-cash { float: right; color: #00ffff; font-weight: bold;}
	.slick-slide { height: auto}
</style>
