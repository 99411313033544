<template>
<div class="">
  <div class="container fadeInDown ">
    <table border="0" cellspacing="0" cellpadding="0" class="join-form">
      <tbody>
        <tr>
          <td height="30" align="left" class="text02">
            <label>아이디<i class="fa fa-asterisk text-danger" aria-hidden="true"></i></label>
            <input name="userid" type="text" class="bg_input" size="25" style="ime-mode:disabled"  v-model="signUpForm.userId"><br>

            <span id="msg_mb_id" style="color: #f00;display: inline-block;">영문자, 숫자, _ 만 입력하세요.</span>
            <span style="color: #b2b2b2;font-size: 11px;">- 영문 소문자, 숫자만 입력 가능하며 최소 3자 이상 입력하세요.</span>
          </td>
        </tr>
        <tr id="rowCertify">
          <td height="30" align="left" class="text02">
            <label>닉네임<i class="fa fa-asterisk text-danger" aria-hidden="true"></i>
            </label>
            <input name="auth_key" type="text" class="bg_input" id="auth_key" size="20" v-model="signUpForm.nickname">
          </td>
        </tr>
        <tr>
          <td height="30" class="text02" align="left"><label>
              패스워드
              <i class="fa fa-asterisk text-danger" aria-hidden="true"></i>
            </label>
            <input name="passwd" type="password" class="bg_input" size="25" v-model="signUpForm.userPw">
          </td>
        </tr>
        <tr>
          <td height="30" class="text02" align="left">
            <label>패스워드확인<i class="fa fa-asterisk text-danger" aria-hidden="true"></i></label>
            <input name="re_passwd" type="password" class="bg_input" id="re_passwd" size="25" v-model="signUpForm.userPw2">
          </td>
        </tr>
        <tr>
          <td height="30" class="text02" align="left">
            <label>
              이름
              <i class="fa fa-asterisk text-danger" aria-hidden="true"></i>
            </label>
            <input name="mb_name" type="text" class="bg_input" id="mb_name" size="25" v-model="signUpForm.bankDepositor">
              <span style="color: #b2b2b2;font-size: 11px;">- 실명이 아닌 경우에 환전시 불이익을 받을 수 있습니다.(공백없이 한글만 입력 가능)</span>
          </td>
        </tr>
        <tr>
          <td height="30" class="text02" align="left">
            <label>거래은행
              <i class="fa fa-asterisk text-danger" aria-hidden="true"></i>
            </label>
            <!-- <select name="bk_name" class="select" id="bk_name" v-model="signUpForm.bankName">
              <option value="">선택</option>
              <option :value="row.name" v-for="(row, index) in bank_name" :key="'bankName' +index">{{ row.name }}</option>
            </select> -->
              계좌번호
              <input name="bk_number" type="text" class="bg_input" id="bk_number" size="25" v-model="signUpForm.bankAccount">
              <span style="color: #b2b2b2;font-size: 11px;" >- 본인 명의의 계좌번호를 입력하세요. 이름과 예금주가 다르면 환전되지 않습니다. </span>
          </td>
        </tr>
        <tr>

            <td height="30" class="text02" align="left"><label>별명<i class="fa fa-asterisk text-danger"
                                                                    aria-hidden="true"></i></label><input
                    name="mb_nick" type="text" class="bg_input" id="mb_nick" size="25">
                &nbsp;&nbsp;<span id="msg_mb_nick"></span> <span style="color: #b2b2b2;font-size: 11px;">- 공백없이 한글, 영문, 숫자만 입력가능합니다.(4자이상)</span>
            </td>
        </tr>
        <tr style="display:none;">
          <td height="30" class="text02" align="left">
            <label>생년월일
              <i class="fa fa-asterisk text-danger" aria-hidden="true"></i>
            </label>
            <input name="mb_birth" type="text" class="bg_input" id="mb_birth" maxlength="6" placeholder="생년월일 6자리" v-model="signUpForm.birth"> 
          </td>
        </tr>
        <tr>
          <td height="30" class="text02" align="left">
            <label>핸드폰번호
              <i class="fa fa-asterisk text-danger" aria-hidden="true"></i>
            </label>
            <select name="agency" id="agency" v-model="signUpForm.telecomName">
              <option value="">통신사선택</option>
              <option value="SKT">SKT</option>
              <option value="KT">KT</option>
              <option value="U+">U+</option>
              <option value="SKT 알뜰폰">SKT 알뜰폰</option>
              <option value="KT 알뜰폰">KT 알뜰폰</option>
              <option value="U+ 알뜰폰">U+ 알뜰폰</option>
            </select>
              <input name="mb_cel" id="mb_cel" type="text" class="bg_input" size="25" v-model="signUpForm.contact">
              &nbsp;
              ( 숫자만 입력 "-" 제외 )
              <span style="color: #b2b2b2;font-size: 11px;">- 긴급 공지사항 전달 시 휴대폰으로 전송되오니 연락가능한 본인 휴대폰번호를 기재해 주세요.</span>
          </td>
        </tr>
        <tr>
          <td height="30" align="left" class="text02">
            <label>환전비밀번호
              <i class="fa fa-asterisk text-danger" aria-hidden="true"></i>
            </label>
            <input name="mb_9" type="password" class="bg_input" id="mb_9" size="20" v-model="signUpForm.cashoutPw">
              <span style="color: #b2b2b2;font-size: 11px;">- 환전시 사용되는 비밀번호 입니다. (필수입력!)</span>
          </td>
        </tr>
        <!--tr>
            <td align="left" class="text01"><p>E-mail</p></td>
            <td height="30" class="text02"><input name="mb_email" type="text" class="bg_input" id="mb_email" size="50"></td>
        </tr-->
        <tr>
          <td height="30" align="left" class="text02">
            <label>추천인
              <i class="fa fa-asterisk text-danger" aria-hidden="true"></i>
            </label>
            <input name="mb_9" type="text" class="bg_input" id="mb_9" size="20" v-model="signUpForm.code">
          </td>
        </tr>
      </tbody>
    </table>
    <div class="btn-zone fadeInDown">
      <button type="submit" class="login-btn" @click="doSignup" >회원가입</button>
      &nbsp;<router-link :to="{ name: '메인' }" class="join-btn">가입취소</router-link>
    </div>
  </div>

</div>
</template>

<script>
import MSG from '@/contants/msg';
// import Loading from '../components/Loading'
export default {
  name: "Signup",
  components: {
    // Loading
  },
  data: function(){
    return {
      signUpForm: {
        userId: null,
        nickname: null,
        userPw: null,
        userPw2: null,
        telecomName: null,
        contact: null,
        cashoutPw: null,
        bankDepositor: null,
        bankName: null,
        bankAccount: null,
        birth: null,
        code: '',
        referrer: '',
      },
      infoAgree: false,
      idPass: false,
      codePass: false,
      nicknamePass: false,
      isProcess: false,
      userIdMsg: null, // 에러 메세지
      nicknameMsg: null, // 에러 메세지
      codeMsg: null, // 에러 메세지
    }
  },
  computed: {
    isView: function(){
      return this.$store.state.modals.signup
    },
    config: function(){
      return this.$store.state.setting.settings;
    },
    bank_name: function(){
      return this.$store.getters['BANK_SETTING']
    }
  },
  watch: {
    'signUpForm.userId': function(val){
      this.userId = val.replace(/[^a-zA-Z0-9]/gi, '')
      const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;

      if(reg.exec(val)!==null){
        return this.signUpForm.userId = this.signUpForm.userId.slice(0,-1);
      }
    },
    isView: function(){
      this.isProcess = false;
    }
  },
  methods: {
    closeModal: function(){
      return this.$store.dispatch('INVISIBLE_ALL_MODALS')
    },
    doSignup: function(){
      // if (!this.infoAgree) return alert('개인정보 수집 및 이용동의를 체크 해주세요');
      let errMsg = '';
      if (this.signUpForm.userPw !== this.signUpForm.userPw2) errMsg = MSG.error.difPw
      if (!this.signUpForm.userId) errMsg = MSG.error.emptyId;
      if (!this.signUpForm.userPw) errMsg =  MSG.error.emptyPassword;
      if (!this.signUpForm.userPw2) errMsg = MSG.error.emptyPassword;
      if (this.signUpForm.userPw.length <= 7) errMsg = MSG.error.shortPassword;
      if (!this.signUpForm.nickname) errMsg = MSG.error.emptyNick;
      if (!this.signUpForm.code) errMsg = MSG.error.emptyCode;
      if (!this.signUpForm.contact) errMsg = MSG.error.emptyContact;
      if (!this.signUpForm.telecomName) errMsg = MSG.error.emptyTelecom;
      if (!this.signUpForm.birth) errMsg = MSG.error.emptyBirth;
      if (!this.signUpForm.bankName) errMsg = MSG.error.emptyBank;
      if (!this.signUpForm.bankDepositor) errMsg = MSG.error.emptyDepositor;
      if (!this.signUpForm.bankAccount) errMsg = MSG.error.emptyBankNum;
      if (!this.signUpForm.cashoutPw) errMsg =  MSG.error.emptyWithdrawalPw;
      if (errMsg !== '') {
        this.$store.state.modals.signup = false;
        return this.$store.dispatch('SHOW_ERR_MSG2', errMsg);
      }
      // if (!this.codePass) return alert('가입하실수 없는 코드입니다.');
      this.isProcess = true;
      return this.$store.dispatch('SIGNUP', this.signUpForm)
          .then(() => {
            this.$store.dispatch('SHOW_ERR_MSG2', MSG.success.signup);
          })
          .catch((error) => {
            this.$store.dispatch('SHOW_ERR_MSG2', error.response.data.error);
          })
    },
  },
}
</script>

<style scoped>
.gender-arr01 {
    display: inline-block !important;
    position: relative;
    margin-left: 0 !important;
}

.gender-dot {
    position: absolute;
    right: 5px;
    top: 6px;
    margin-top: 0 !important;
    font-size: 14px;
}

.join-form {    margin: 0 auto;  overflow:hidden; font-size:12px; width:700px;  }
.join-form td { color: #fff; }
.join-form td { padding: 5px 12px; }

.join-form span { display: block; margin-top: 8px; margin-left: 125px; }
.text01 { text-align:center; background:rgba(30,30,30,0.3); color:#fff !important;  font-weight:bold;  text-align: right; padding-left: 10px;}
input[type='text'], input[type='password'], select {  border:none;     color: #fff;  font-size: 12px; padding: 2px 5px; line-height: 35px; height: 35px; transition: 0.2s; vertical-align: middle; border-radius: 0px;border: solid 1px #444;   background: #000;  }

select { height: 35px; line-height: 35px; }
input[type='text']:focus, input[type='password']:focus, select:focus, textarea:focus { outline: none; color: #fff;   border: 1px solid #00ffff;  }
.container {   
    position: absolute;
   
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #333;
    width: 700px;
    overflow: visible;
   
    background: #151515;
    backdrop-filter: blur(10px);
    padding: 20px;
	

	
}
.btn-join {background-color: #30790f; color: #fff; border-radius: 4px; border: none; width: 100px; height: 40px; line-height: 40px; box-sizing: border-box; font-size:12px; font-weight: bold; text-decoration: none; cursor: pointer; transition: 0.2s;}
.btn-cancle { background-color: #a10101; color: #fff;border-radius: 4px; border: none; width: 100px; height: 40px; line-height: 40px; box-sizing: border-box; font-size:12px; font-weight: bold; text-decoration: none;cursor: pointer; transition: 0.2s;}	
		.btn-join:hover, .btn-cancle:hover{ opacity: 0.6;}		
		label { display: inline-block; text-align: right; width: 110px; padding-right: 10px; font-size: 14px; font-weight: bold;}
		label .fa { color:#00ffff; margin-left: 5px; margin-bottom: 5px; font-size:10px; }
		
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
  
  }
  100% {
    opacity: 1;
 
  }
}		
	

		.btn-zone{text-align: center; padding-top: 20px; margin-top: 20px; border-top: solid 1px #444; }	
 .video-bg {width:100%; height:100%; position:absolute; left:0; top:0; background:url(/html/upcasino/images/bg-video.png?33131); overflow:hidden; }
	

.btn-zone a, .btn-zone button {
 
  border: none;
  color: white;

  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer;
	width: 150px;
  font-size: 13px;
	font-weight: bold;
	color: #fff;
	height: 48px;
	line-height: 48px;
box-sizing: border-box;
    vertical-align: middle;


  

  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

    color: #ffffff;
}

		.btn-zone a:hover, .btn-zone button:hover  {  filter: brightness(1.2)}
		
.video_bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}	
.umMovie {
    visibility: visible;
    margin: auto;
    min-width: 100%;
    min-height: 100%;
	margin-top: -50px;
}
.video_filter {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url("/html/upcasino/images/patten_login_bg.png?2") repeat;
}
 .border {
    background: #4c4524;
}
        .fill {background: #0f0f0f;}
        .btn_signup { width: 300px; height: 50px; line-height: 50px;}	
        
        
.login-btn, .join-btn {
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  display: block;
  margin-top: 10px;
  background: #00ffff;
  border-radius: 10px;
  font-weight: 500;
  color: #fff;
  border: 1px solid #000;
  box-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3) inset;
  text-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  vertical-align: middle;
}
.login-btn {
  background: linear-gradient(#00ffff 0%, #007eff 100%);
}
	.join-btn {    background: linear-gradient(#7c7c7c 0%, #353535 100%);}      
	.login-btn:hover{background:linear-gradient(-45deg, #00ffff 0%, #007eff 100%);}
	.join-btn:hover{background:linear-gradient(-45deg, #7c7c7c 0%, #353535 100%);}	
</style>
