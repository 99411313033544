import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './helper/filters'
import './helper/plugins'


import VueMobileDetection from 'vue-mobile-detection'
import VStickyElement from 'vue-sticky-element';
import VueSocketIOExt from 'vue-socket.io-extended';
import { io } from 'socket.io-client';
import VueCookies from "vue-cookies";
import Vuetify from 'vuetify'
// import * as Sentry from "@sentry/vue";

Vue.config.productionTip = false

import './assets/css/common.css'
import './assets/css/button.css'
// import './assets/css/default2.css'

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

library.add(fas);
library.add(far);
Vue.component('font-awesome-icon', FontAwesomeIcon)


Vue.use(VueMobileDetection);
Vue.use(VStickyElement);
Vue.use(VueCookies);
Vue.use(Vuetify);

const SOCKET = io(process.env.VUE_APP_SOCKET_URL, {
  reconnection: true,
  reconnectionDelay: 2000,
});

Vue.use(VueSocketIOExt, SOCKET);

new Vue({
  router,
  store,
  vuetify: new Vuetify({
    icons: {
      iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
  }),
  created(){
    // created lifecycle hook 생성
    const userString = localStorage.getItem('user');
    if (userString) {
      const userData = JSON.parse(userString);
      this.$store.commit('ME', userData);
    }
  },
  render: h => h(App),
}).$mount('#app')




