<template>
  <div class="sub-topcont11">
    <div class="noticeText"><img src="../assets/images/notice_title.png">
      <marquee direction="left">
      </marquee>
    </div>
    <ul class="user-info01 topbtn-arr" v-if="!user">

      <li>
        <button @click="$store.dispatch('SHOW_MODAL', 'login')" class="login-modal">
          <i class="fa fa-user-circle-o" aria-hidden="true"></i> 
          로그인
        </button>
      </li>   
      <li>
        <router-link :to="{name: '회원가입'}">
          <i class="fa fa-plus-circle" aria-hidden="true"></i>  
          회원가입
        </router-link>
      </li>
    </ul>
    <ul class="user-info01" v-else>
      <li><span class="level_icon"></span> <strong id="mb_nick">{{ user.members_nickname }}</strong>님 <a href="memo.asp"> <i class="fi fi-tr-envelopes"></i> <strong id="i_rank" name="i_rank">{{ user.notes.length }}</strong> 통</a></li>
      <li><a href="cash_01.asp"><i class="fi fi-tr-square-m"></i> 보유금액 : <span class="mb_point">{{ user.members_cash | makeComma }}</span>원 </a></li>
      <li><a href="javascript:void(0)" class="PointChange" intmp="0" style="cursor: pointer;"><i class="fi fi-tr-square-p"></i> 포인트 : <strong class="mb_mileage">{{ user.members_point }}</strong>원  [전환]</a></li>
      <!-- <li> <i class="fi fi-tr-square-c"></i> 카지노머니 : <span class="casino_point">0</span>원
        <button type="button" class="getCasinoPoint" onclick="javascript:getCasinoPoint();"> <i class="icofont icofont-refresh"></i> </button>
        <button type="button" class="chageCasinoPoint" onclick="javascript:chageCasinoPoint();"> 머니전환 </button>
      </li> -->
      <li><a href="/logout.asp" class="logouttopa" title="로그아웃"> 로그아웃</a></li>
    </ul>
  </div>
</template>

<script>
// import CurrentTime from '@/components/CurrentTime';
import {mapState} from "vuex";
import MSG from "@/contants/msg";
// import Loading from '@/components/Loading'
// import CurrentLanguage from '@/components/CurrentLanguage';

export default {
  name: "Header",
  components: {
    // CurrentTime,
    // Loading,
    // CurrentLanguage
  },
  props:['currentMenu'],
  data(){
    return {
      navHeader: false,
      toggle: false,
      toggle2: false,
      signInForm: {
        userId: null,
        userPw: null,
        sitename: 'casino',
        version: null,
      },
      subMenu: '',
      proceeding: false,
    }
  },
  computed: {
    menuVisible(){
      return this.$store.getters['MENU_VISIBLE'];
    },
    inplayVisible(){
      return this.menuVisible?.find(e => e.name === '인플레이').visible
    },
    europeanVisible(){
      return this.menuVisible?.find(e => e.name === '스포츠-유럽형').visible
    },
    koreanVisible(){
      return this.menuVisible?.find(e => e.name === '스포츠-한국형').visible
    },
    korean2Visible(){
      return this.menuVisible?.find(e => e.name === '스포츠-한국형2').visible
    },
    liveVisible(){
      return this.menuVisible?.find(e => e.name === '라이브').visible
    },
    ...mapState({
      user: state => state.user.user,
      version: state => state.version.version,
    }),
  },
  methods:{
    logOut: function(){
      localStorage.removeItem('authToken');
      this.$store.dispatch("LOGOUT", null)
      this.$router.push({ path: '/main'})
    },
    handleScroll () {
      if (window.scrollY > 30) {
        this.navHeader = true;
      } else {
        this.navHeader = false;
      }
      // Any code to be executed when the window is scrolled
    },
    doLogin: function() {
      this.proceeding = true
      if(!this.signInForm.userId) return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.emptyId);
      if(!this.signInForm.userPw) return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.emptyPassword);
      this.signInForm.version = this.version
      this.$store.dispatch('LOGIN', this.signInForm)
          .then((response) => {
            if (response.status === 200) {
              // 로그인 성공시 처리
              this.$store.dispatch('SHOW_ERR_MSG', MSG.success.welcome);
              this.signInForm.userId = null
              this.signInForm.userPw = null
              this.proceeding = false
            }
          })
          .catch((error) => {
            // 로그인 실패시 처리
            this.$store.dispatch('SHOW_ERR_MSG', error.response.data.error);
            this.proceeding = false
            // alert(error.response.data.error);
          })
    },
    toggleModalTop: function() {
      this.toggle2 = !this.toggle2
      this.toggle = false;
    },
    toggleModalUser: function() {
      this.toggle = !this.toggle
      this.toggle2 = false;
    },
    subMouseOut(){
      // console.log('mouse out');
      this.subMenu = '';
    },
    subMouseIn(menu){
      // console.log(menu);
      this.subMenu = menu;
    },
    openCasino: function(game){
      if (!this.user) return alert(MSG.isNotLogin);
      const config = this.user.members_detail_setting;
      this.$store.dispatch('CHECK_CASINO_WALLET');
      if (game === 'evolution' && !this.menuVisible.find(e => e.name === '에볼루션').visible){
        return this.$store.dispatch('SHOW_ERR_MSG3', '에볼루션 점검중입니다.');
      }
      if (game === 'asia' && !this.menuVisible.find(e => e.name === '아시아').visible){
        return this.$store.dispatch('SHOW_ERR_MSG3', '아시아는 점검중입니다.');
      }
      if (game === 'evolution' && !config.베팅권한.에볼루션.active){
        this.$store.dispatch('SET_ERR_MSG', '에볼루션은 이용하실 수 없습니다.');
        this.$store.dispatch('SHOW_MODAL', 'error2');
        return;
      }
      if (game === 'asia' && !config.베팅권한.아시아게이밍.active){
        this.$store.dispatch('SET_ERR_MSG', '아시아게이밍은 이용하실 수 없습니다.');
        this.$store.dispatch('SHOW_MODAL', 'error2');
        return;
      }
      const data = {
        payload: {
          type: game === 'evolution' ? 45 : 44,
        }
      }
      this.$store.dispatch('GET_LOBBY', data)
          .then(data => {
            const result = data.data;
            if (result.payload) {
              window.open(result.payload.data.lobbyUrl, 'Casino', '_blank')
            }
          })
          .catch(err => {
            alert(err.response.data.error);
          })
    },
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>
