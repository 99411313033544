<template>
<div class="sub_content_arr_bg">
  <div class="sub_content_arr">
      <div>
          <form name="frm" id="frm" action="/notice.asp" method="post">
              <input type="hidden" name="page" value="1">
              <input type="hidden" name="code_group" value="65">
              <input type="hidden" name="code_no" value="65">
              <input type="hidden" name="code" value="7">
              <div class="subTitle_notice"><h2>공지사항 <small>notice</small></h2></div>
              <!-- 서브타이틀 -->

              <table width="100%" border="0" cellpadding="0" cellspacing="0" class="topth">
                  <tbody>
                  <tr align="center">
                      <th width="70" height="24">번호</th>
                      <th>제목</th>
                      <th width="126" align="left" style="">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;작성자</th>
                      <th width="110" style="">작성일시</th>
                      <th width="70" style="display:none">조회수</th>
                  </tr>
                  </tbody>
              </table>
              <table width="100%" border="0" cellpadding="0" cellspacing="0" class="bbs-list">
                  <tbody>
                  <tr class="notice" v-for="(row, index) in lists" :key="'notice'+index">
                      <td width="70" height="30" align="center" class="num">
                        <img src="../../../assets/images/icon_notice.gif">
                      </td>
                      <td class="subject" style="text-align:left">
                        <a href="?mode=read&amp;seq=20&amp;code_group=65&amp;code_no=65&amp;code=7&amp;Page.IsBack.List=JTJGbm90aWNlJTJFYXNwJTNG">
                          {{ row.board_title }}
                        </a>
                        <span class="num_style"></span>
                      </td>
                      <td width="126" class="writer" style=""> 관리자 </td>
                      <td width="110" align="center" class="date" style="">-</td>
                      <td width="70" align="center" class="hit" style="display:none">-</td>
                  </tr>
                  </tbody>
                  <tbody id="lists">

                  </tbody>
              </table>

              <!-- 페이징 시작 -->
              <div class="list_bottom_btn_left"></div>
              <div class="list_bottom_btn_right">
                  <a href="javascript:void(0);" class="ui_btn_blue" name="btnCustomer" id="btnCustomer"
                    style=" color:#fff; cursor:pointer;display:none">계좌문의</a>&nbsp;
                  <a href="javascript:void(0);" class="ui_btn_red" name="newPop" id="newPop"
                    style="cursor:pointer;display:none">글쓰기</a>
              </div>
              <div class="clear"></div>
              <div class="paging_list"> <!--<TABLE cellSpacing=0 cellPadding=0 id='speedpage'>
  <TR>
  <TD align=center style='PADDING:2px 4px 0 2px' valign=middle>
  <img src="../../../assets/images/icon/end_1.gif" class="mt03" border="0">
  <img src="../../../assets/images/icon/next_1.gif" class="mt03" border="0">
  </td>
  <TD bgcolor=#DDDDDD noWrap width=1 height=13></TD><TD align=center  style='PADDING:2px 0 0 4px;'>
  <img src="../../../assets/images/icon/next.gif" class="mt03" border="0">
  <img src="../../../assets/images/icon/end.gif" class="mt03" border="0">
  </td></tr></table>

  -->
                  <div class="tf_pagination">
                      <ul>
                          <li><a id="pre-btn" href="javascript:void(0);"><i class="fa fa-chevron-left"
                                                                            aria-hidden="true"></i></a></li>
                          <li><a class="active">1</a></li>
                          <li><a id="next-btn" href="javascript:void(0);"><i class="fa fa-chevron-right"
                                                                            aria-hidden="true"></i></a></li>
                      </ul>
                  </div>
              </div>
              <!--div class="search_zone_arr">
                          <select class="input_text" name="keyfiled" id="keyfield" style="height:21px;font-family:돋움" align="absmiddle">
                                  <option value="title" selected>제목</option><option value="id" >아이디</option><option value="name" >작성자</option>
                          </select>
                          <input class="input_text" type="text" name="keyword" id="keyword" value="" style="height:16px;font-family:돋움; color:#000;" align="absmiddle">
                          <input type="image" src="../../../assets/images/btn_search001.png" align="absmiddle" >
                  </div-->
          </form>
          <div class="clear"></div>
      </div>
      <div class="clear"></div>
  </div>
  <div class="clear"></div>
</div>

</template>

<script>
// import Pagination from '../../../components/Pagination01'
// import SideLink from '../../../components/Board/SideLink'
// import Loading from '../../../components/Loading'

export default {
  name: "Event",
  components: {
    // Pagination,
    // SideLink,
    // Loading
  },
  data: function(){
    return {
      path: 'bbs/n/l',
      lists: null,
      isLoading: false,
      payload: {
        page: 1,
        size: 20,
        keyword: null,
        type: ['notice', 'event'],
        sitename: 'casino'
      },
      expandedArticle: []
    }
  },
  mounted: function() {
    this.reqData(this.payload)
  },
  methods: {
    reqData: function(payload){
      this.isLoading = false;
      return this.$store.dispatch('GET_BOARD', { payload })
          .then(data => {
            const result = data.data;
            this.lists = result.payload.list;
            this.isLoading = true;
            console.log('페이로드', Array.isArray(this.payload.type))
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    changeType (type){
      this.payload.type = type
      this.reqData(this.payload);
    },
    movePage: function(page){
      this.$router.push({path: `/${page}`})
    },
    renderHtml: function(str){
      str.replace(/<img/g, '<img ')
      str.replace(/(?:\r\n|\r|\n)/g, '<br />')
      return str.split('\n').join('<br />');
    },
    toggleArticle: function(index) {
      if(this.expandedArticle.indexOf(index)>=0) {
        this.expandedArticle.splice(this.expandedArticle.indexOf(index), 1);
      }
      else this.expandedArticle.push(index);
    },
  }
}
</script>

<style scoped>
/*-------------------------------------------------------------------------------------*
 *  Contents                                                                           *
 *-------------------------------------------------------------------------------------*/
#contents_wrap {float:left; width:100%; padding:0 0 50px 0; background-color: #1c2021;}
.contents {width:1330px; margin:0px auto; min-height:600px; }
.contents_in_m20 {width:100%; float:left; margin:0 0 20px 0}

.tab_container {clear: both; float: left; width: 100%;}
.tab_content {padding: 20px 0 0 0;}

/*fade*/
.tab_content.fading{
  opacity: 1;
  animation: fading 0.4s linear;
}

.fading {
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-duration: 705ms;
  -moz-animation-timing-function: ease-in-out;
  -moz-animation-duration: 705ms;
}

@keyframes fading {
  from { opacity: 0 }
  to { opacity: 1 }

}

.notice_img{width:300px; height:180px; display: block;}
.notice_img img{width:300px; height:180px;}
.notice_text {text-align:center; width:280px;padding:0 10px 0 10px;  display: block;}
.notice_text_t {color:#ffffff; margin:20px 0 0 0}
.notice_text_c { margin:10px 0 0 0}
.view_c_in_img {margin:0 30px 0 0; float:left;}
.view_c_in_t {width:100%; margin:0 0 20px 0; float:left;}
.view_c_in {width:100%;}
.view_title {float:left; padding:30px 0 0 0 }
.view_t {font-size:30px; color:#ffffff; letter-spacing:-1px; line-height:35px; text-align:left}
.view_center {width:1330px; margin:0px auto}
.view_date {margin:10px 0 0 0; text-align:left}
.view_c_in_text { text-align:left}
.viewchange .resultitem {vertical-align: top;display: block; margin: 10px 12px 0;position: relative;text-align:center}
.viewchange .resultitem .box {vertical-align: top;display: block;overflow: hidden; background-size: cover; position: relative;cursor: pointer;}
.viewchange .resultitem>.box>a.clickevent { position: absolute;top: 0px; bottom: 0px;left: 0px;right: 0px;height: auto;}
.picflex {display: flex; flex-wrap: wrap;}
.picflex .resultitem {position: static;width: auto;border: 0;height: 260px;flex-grow: 1;}
.picflex .resultitem .box {border: none;width: 300px; height: 260px;line-height: normal; }
.picflex .resultitem.open {height: 675px;}
.picflex .resultitem.open:after {content: "";font-size: 0px;width: 0;height: 0;border-style: solid;border-width: 0px 20px 20px 20px; border-color: transparent transparent #121516 transparent; top: -10px;position: relative;}
.picflex .detailview {display: none; background: #121516;height: 395px;position: absolute;left: 0;right: 0;margin-top: 20px;overflow: auto;}
.picflex .resultitem.open .detailview {display: block;}
.controllers {text-align: right;}
.controllers a {display: inline-block;margin:30px 0 0 0;}

.picflex .resultitem{
  max-width: 308px!important;
}

.view_c_in_img img{
  width: 300px;
  height: 180px;
}
.view_c_in_img{
  margin:0px!important;
  width: 30%!important;
  float: left;
  text-align: left;

}
.view_c_in_text{
  width: 70%!important;
  float:right!important;
  margin-bottom: 20px;

}

.view_c_in_text p{
  padding:6px;
}
.picflex .detailview{
  min-height: 396px;
  height: auto!important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
</style>
