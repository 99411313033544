<template>
  <div class="sub_content_arr" v-if="!this.$store.state.page.isMobile">
      <div class="subTitle_arr">
          <h2>라이브카지노 <small>live casino</small></h2>
      </div>

      <div class="real_list">
          <a class="companys-title code1 login-modal" data-thirdpartycode="1" data-gamecode="evolution_top_games" @click="openCasino('evolution_all_games')">
              <div class="border"><img class="gmae_img" src="../../../assets/images/casino/casino_1.png">
                  <div class="game_title">에볼루션</div>
              </div>
          </a> 
          <a class="companys-title code33 login-modal" data-thirdpartycode="33" data-gamecode="101" @click="openCasino('101')">
            <div class="border"><img class="gmae_img" src="../../../assets/images/casino/casino_33.png">
                <div class="game_title">프라그마틱</div>
            </div>
          </a> 
      <!-- <a class="companys-title code12 login-modal" data-thirdpartycode="12" data-gamecode="casino">
          <div class="border"><img class="gmae_img" src="../../../assets/images/casino/casino_12.png">
              <div class="game_title">WM카지노</div>
          </div>
      </a>  -->
      <a class="companys-title code8 login-modal" data-thirdpartycode="8" data-gamecode="casino" @click="openCasino('dgcasino')">
          <div class="border"><img class="gmae_img" src="../../../assets/images/casino/casino_8.png">
              <div class="game_title">드림게임</div>
          </div>
      </a> 
      <!-- <a class="companys-title code46 login-modal" data-thirdpartycode="46" data-gamecode="BTV-speedy7">
          <div class="border"><img class="gmae_img" src="../../../assets/images/casino/casino_46.png">
              <div class="game_title">벳게임즈</div>
          </div>
      </a> 
      <a class="companys-title code47 login-modal" data-thirdpartycode="47" data-gamecode="SWL-baccarat">
          <div class="border"><img class="gmae_img" src="../../../assets/images/casino/casino_47.png">
              <div class="game_title">스카이윈드</div>
          </div>
      </a>  -->
      <!-- <a class="companys-title code51 login-modal" data-thirdpartycode="51" data-gamecode="taishan">
          <div class="border"><img class="gmae_img" src="../../../assets/images/casino/casino_51.png">
              <div class="game_title">타이산</div>
          </div>
      </a>  -->
      <a class="companys-title code54 login-modal" data-thirdpartycode="54" @click="openCasino('MGL_GRAND_LobbyAll')"
              data-gamecode="SMG_titaniumLiveGames_MP_Baccarat">
          <div class="border"><img class="gmae_img" src="../../../assets/images/casino/casino_54.png">
              <div class="game_title">마이크로게이밍</div>
          </div>
      </a> 
      <a class="companys-title code28 login-modal" data-thirdpartycode="28" data-gamecode="speed"  @click="openCasino('bota')">
          <div class="border"><img class="gmae_img" src="../../../assets/images/casino/casino_28.png">
              <div class="game_title">보타(TG/PD)</div>
          </div>
      </a> 
      <a class="companys-title code53 login-modal" data-thirdpartycode="53" data-gamecode="dowin" @click="openCasino('dowin')">
          <div class="border"><img class="gmae_img" src="../../../assets/images/casino/casino_53.png">
              <div class="game_title">두윈카지노</div>
          </div>
      </a> 
      <a class="companys-title code60 login-modal" data-thirdpartycode="60" data-gamecode="" @click="openCasino('ptx_ubal_lobby')">
          <div class="border"><img class="gmae_img" src="../../../assets/images/casino/casino_60.png">
              <div class="game_title">플레이테크카지노</div>
          </div>
      </a>
      <!-- <a class="companys-title code13 login-modal" data-thirdpartycode="13" data-gamecode="">
          <div class="border"><img class="gmae_img" src="../../../assets/images/casino/casino_13.png">
              <div class="game_title">비보게이밍</div>
          </div>
      </a>  -->
      <!-- <a class="companys-title code63 login-modal" data-thirdpartycode="63" data-gamecode="">
          <div class="border"><img class="gmae_img" src="../../../assets/images/casino/casino_63.png">
              <div class="game_title">모티베이션</div>
          </div>
      </a> -->
       <a class="companys-title code67 login-modal" data-thirdpartycode="67" data-gamecode=""  @click="openCasino('MGL_GRAND_LobbyAll')">
          <div class="border"><img class="gmae_img" src="../../../assets/images/casino/casino_67.png">
              <div class="game_title">MG Live Grand</div>
          </div>
      </a> 
      <!-- <a class="companys-title code66 login-modal" data-thirdpartycode="66" data-gamecode="">
          <div class="border"><img class="gmae_img" src="../../../assets/images/casino/casino_66.png">
              <div class="game_title">아스타</div>
          </div>
      </a> -->


      </div>


      <div class="container">


      </div>
  </div>
</template>

<script>
//import "swiper/css/swiper.css";
//import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { mapState } from "vuex";
import MSG from "@/contants/msg";

export default {
  name: "Main",
  components: {
    //Swiper,
    //SwiperSlide,
  },
  data: function () {
    return {
      lists: [],
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 10,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      liveCasinoGameList: [],
      liveCasinoLobbyList: [],
      selectedCategory: null,
      extention: false,
      bookMarkedGameList: [],
      selectedGameList: [],
      categories: [],
      originList: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
    menuVisible(){
      return this.$store.getters['MENU_VISIBLE'];
    },
  },

  mounted() {
    if (!this.menuVisible.find(e => e.name === '라이브카지노').visible){
      return this.$store.dispatch('SHOW_ERR_MSG3', '라이브 카지노는 점검중입니다.');
    }
    // this.checkMemberType();
    this.goToTop();
    this.reqData();
    console.log(this.lists);
  },
  methods: {
    reqData: function () {
      this.$store
        .dispatch("GET_LIVE")
        .then((res) => {
          const data = res.data;
          this.originList = data.payload.list;
          this.categories = data.payload.category;
          this.lists = this.originList;
          //this.liveCasinoLobbyList = data.live.liveCasinoLobbyList;
        });
    },
    changeCategory: function (category) {
      this.selectedCategory = category;
    },
    changeMobileCategory: function (category) {
      this.extention = false;
      this.selectedGameList = this.liveCasinoGameList.find(liveCasinoGame=> liveCasinoGame.name === category)
    },
    openCasino: function (game_id) {
      if (!this.user) return alert(MSG.isNotLogin);

      const data = {
        payload: {
          game_id,
        },
      };
      this.$store
        .dispatch("GET_GAME", data)
        .then((data) => {
          const result = data.data;
          console.log("리절트", result);
          if (result.success) {
            window.open(result.payload.link.link, "Casino", "_blank");
          } else {
            alert(result.payload.msg);
          }
        })
        .catch((err) => {
          alert(err.response.data.error);
        });
    },
    movePage: function (page) {
      this.$router.push({ path: `/${page}` });
    },
    goToTop: function () {
      window.scrollTo(0, 0);
    },
    extendList: function () {
      this.extention = true
    },
    checkMemberType: function () {
      if(this.user.members_type === '정산제외회원') return
      else {
        alert('카지노는 점검 중입니다.')
        this.$router.push({ path: '/main' })
      }
    },
  },
};
</script>

<style scoped>
@media(min-width: 1000px){
.pages-container {
    width: 100%;
    min-width: 1440px;
    padding: 24px 24px 24px 24px;
    background-color: #1f2126;
    background-size: calc(100% - 250px) 100%;
    background-position: 250px 0
}

.pages-container .title-container {
    padding-bottom: 24px;
    display: flex
}

.pages-container .title-container .text-box {
    width: 100%;
    display: flex;
    gap: 8px;
    padding-bottom: 12px;
    align-items: center;
    border-bottom: 1px solid #041a41
}

.pages-container .title-container .text-box .title {
    color: #041a41;
    font-size: 24px;
    font-weight: 700
}

.live-container {
    display: flex;
    grid-template-columns: 484px 1fr;
    justify-content: space-around;
    gap: 16px
}

.live-container .left {
    display: flex;
    aspect-ratio: 484/595
}

.live-container .right .card-wrap {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px
}

.live-container .right .card-wrap .card {
    cursor: pointer;
    height: 150px !important;
}
.live-container .right .card-wrap .card img {
    width: 378px;
}
/*# sourceMappingURL=pc.min.css.map */
}

@media(max-width: 600px){
.pages-container{width:100%;padding:20px 5px}
.pages-container .title-container{padding-bottom:20px;display:flex}
.pages-container .title-container .text-box{width:100%;display:flex;gap:8px;padding-bottom:8px;align-items:center;border-bottom:1px solid #041a41}
.pages-container .title-container .text-box .title{color:#041a41;font-size:20px;font-weight:700}
.pages-container .title-container .text-box .desc{font-size:12px}
.left { display: none; }
.live-container .card-wrap{display:grid;gap:8px}/*# sourceMappingURL=mobile.min.css.map */
.live-container .card-wrap .card {
    cursor: pointer;
    height: 150px !important;
}
}


</style>
