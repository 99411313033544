<template>

  <div class="repair">
    <div>
      <!-- <img src="@/assets/img/etc/logo.png"> -->
      <strong>잘못된 경로로 접근 되었습니다.</strong>
      <p>메인 페이지로 이동하셔서 로그인 해 주세요.</p>
      <router-link to="/main">메인 페이지로 가기</router-link>
    </div>
  </div>

</template>

<script>
export default {
  name: "404"
}
</script>

<style scoped>

</style>
