var render = function render(){var _vm=this,_c=_vm._self._c;return (!this.$store.state.page.isMobile)?_c('div',{staticClass:"sub_content_arr"},[_vm._m(0),_c('div',{staticClass:"real_list"},[_c('a',{staticClass:"companys-title code1 login-modal",attrs:{"data-thirdpartycode":"1","data-gamecode":"evolution_top_games"},on:{"click":function($event){return _vm.openCasino('evolution_all_games')}}},[_vm._m(1)]),_c('a',{staticClass:"companys-title code33 login-modal",attrs:{"data-thirdpartycode":"33","data-gamecode":"101"},on:{"click":function($event){return _vm.openCasino('101')}}},[_vm._m(2)]),_c('a',{staticClass:"companys-title code8 login-modal",attrs:{"data-thirdpartycode":"8","data-gamecode":"casino"},on:{"click":function($event){return _vm.openCasino('dgcasino')}}},[_vm._m(3)]),_c('a',{staticClass:"companys-title code54 login-modal",attrs:{"data-thirdpartycode":"54","data-gamecode":"SMG_titaniumLiveGames_MP_Baccarat"},on:{"click":function($event){return _vm.openCasino('MGL_GRAND_LobbyAll')}}},[_vm._m(4)]),_c('a',{staticClass:"companys-title code28 login-modal",attrs:{"data-thirdpartycode":"28","data-gamecode":"speed"},on:{"click":function($event){return _vm.openCasino('bota')}}},[_vm._m(5)]),_c('a',{staticClass:"companys-title code53 login-modal",attrs:{"data-thirdpartycode":"53","data-gamecode":"dowin"},on:{"click":function($event){return _vm.openCasino('dowin')}}},[_vm._m(6)]),_c('a',{staticClass:"companys-title code60 login-modal",attrs:{"data-thirdpartycode":"60","data-gamecode":""},on:{"click":function($event){return _vm.openCasino('ptx_ubal_lobby')}}},[_vm._m(7)]),_c('a',{staticClass:"companys-title code67 login-modal",attrs:{"data-thirdpartycode":"67","data-gamecode":""},on:{"click":function($event){return _vm.openCasino('MGL_GRAND_LobbyAll')}}},[_vm._m(8)])]),_c('div',{staticClass:"container"})]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subTitle_arr"},[_c('h2',[_vm._v("라이브카지노 "),_c('small',[_vm._v("live casino")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border"},[_c('img',{staticClass:"gmae_img",attrs:{"src":require("../../../assets/images/casino/casino_1.png")}}),_c('div',{staticClass:"game_title"},[_vm._v("에볼루션")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border"},[_c('img',{staticClass:"gmae_img",attrs:{"src":require("../../../assets/images/casino/casino_33.png")}}),_c('div',{staticClass:"game_title"},[_vm._v("프라그마틱")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border"},[_c('img',{staticClass:"gmae_img",attrs:{"src":require("../../../assets/images/casino/casino_8.png")}}),_c('div',{staticClass:"game_title"},[_vm._v("드림게임")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border"},[_c('img',{staticClass:"gmae_img",attrs:{"src":require("../../../assets/images/casino/casino_54.png")}}),_c('div',{staticClass:"game_title"},[_vm._v("마이크로게이밍")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border"},[_c('img',{staticClass:"gmae_img",attrs:{"src":require("../../../assets/images/casino/casino_28.png")}}),_c('div',{staticClass:"game_title"},[_vm._v("보타(TG/PD)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border"},[_c('img',{staticClass:"gmae_img",attrs:{"src":require("../../../assets/images/casino/casino_53.png")}}),_c('div',{staticClass:"game_title"},[_vm._v("두윈카지노")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border"},[_c('img',{staticClass:"gmae_img",attrs:{"src":require("../../../assets/images/casino/casino_60.png")}}),_c('div',{staticClass:"game_title"},[_vm._v("플레이테크카지노")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border"},[_c('img',{staticClass:"gmae_img",attrs:{"src":require("../../../assets/images/casino/casino_67.png")}}),_c('div',{staticClass:"game_title"},[_vm._v("MG Live Grand")])])
}]

export { render, staticRenderFns }